import { useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../../layout/MainContainer";
import UserListFilter from "../../../features/userManagement/userFilter";
import UserListTable from "../../../features/userManagement/userListTable";
import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { showError } from "../../../constants/toasts";
import {
  useLazyGetSubAdminsQuery,
  useDeleteSubAdminByIdMutation,
  useEditSubAdminByIdMutation,
} from "../../../services/subAdmin";
import { useLazyGetRolesQuery } from "../../../services/subadminRoles";
import { useGridApiRef } from "@mui/x-data-grid";
import Loader from "../../../constants/Loader";

const UserList = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [subAdmins, setSubAdmins] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  console.log(searchValue);
  const [getSubAdmins] = useLazyGetSubAdminsQuery();
  const [updateAdminStatus]: any = useEditSubAdminByIdMutation();
  const [deleteById] = useDeleteSubAdminByIdMutation();
  const [rolesData, setRolesData] = useState([]);
  const [type, setType] = useState(-1);
  //1 pending
  //2 enable
  //3 disable
  //4 blocked
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>({
    row: [],
    column: [],
  });
  console.log("selectedRow :", selectedRow);

  const gridApi = useGridApiRef();
  const [getRoles] = useLazyGetRolesQuery();
  const getRolesList = async () => {
    try {
      const response = await getRoles({
        // page: page,
        // limit: 10,
        // query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log("ROLE", response);
        setRolesData(response?.data?.data || []);
        response?.data?.data.map(
          (data: any) => data?._id == id && setSelectedRole(data?.role)
        );
        // setTotalCount(response?.data?.count);
      } else {
        setRolesData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const getSubAdminsList = async () => {
    setIsLoading(true);
    try {
      const response = await getSubAdmins({
        page: 1,
        // limit: 10,
        query: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        console.log("SUB", response);
        if (id) {
          //@ts-ignore
          response?.data?.subAdmin?.map((data: any) => {
            console.log("DATA1", data?.subadminRoleId?._id?.toLowerCase());
            console.log("DATA2", id);
          });

          setSubAdmins(
            response?.data?.subAdmin.filter(
              (data: any) =>
                data?.subadminRoleId?._id?.toLowerCase() == id?.toLowerCase()
            )
          );
        } else setSubAdmins(response?.data?.subAdmin || []);
        setTotalCount(response?.data?.count);
      } else {
        setSubAdmins([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };

  function getFilteredUsers() {
    let tempData = subAdmins;
    if (searchValue !== "") {
      tempData = tempData.filter(
        (data: any) =>
          data?.fullName?.toLowerCase()?.includes(searchValue?.toLowerCase()) ||
          data?.email?.toLowerCase()?.includes(searchValue?.toLowerCase())
      );
    }
    if (type !== -1) {
      if (type == 1)
        tempData = tempData.filter((data: any) => data?.accountStatus == 1);

      if (type == 2)
        tempData = tempData.filter(
          (data: any) => data?.accountStatus == 2 && !data?.isBlocked
        );

      if (type == 3)
        tempData = tempData.filter(
          (data: any) => data?.accountStatus == 2 && data?.isBlocked
        );

      if (type == 4)
        tempData = tempData.filter((data: any) => data?.accountStatus == 3);
    }
    return tempData;
  }

  useEffect(() => {
    //   console.log("TESTING")
    getSubAdminsList();
    getRolesList();
  }, []);
  return (
    <>
      <Loader isLoad={isLoading} />
      <>
        <div className="main_title">
          <h1 style={{ textTransform: "capitalize" }}>
            {selectedRole && selectedRole + "'s "}User List
          </h1>
          <p>
            <span onClick={() => navigate("/dashboard")}>Dashboard</span> - User
            Management -{id && "Roles -"} User List
          </p>
        </div>
        <div className="cards">
          <UserListFilter
            gridApi={gridApi}
            selectedRow={selectedRow}
            setType={setType}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            roles={rolesData}
            getSubAdminsList={getSubAdminsList}
          />
          <UserListTable
            gridApi={gridApi}
            setSelectedRow={setSelectedRow}
            roles={rolesData}
            subAdmins={getFilteredUsers()}
            getSubAdminsList={getSubAdminsList}
          />
        </div>
      </>
    </>
  );
};

export default UserList;
