//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { any } from "../types/beneficiary";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllBeneficiaryResponse = {
  data: any;
  // beneficiary: any[];
  count: number;
};

type GetTokenParams = {
  // limit?: number;
  // wpagination?:boolean;
  type?: number;
  page?: number;
  query?: string;
};
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi: any = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBeneficiary: builder.query<
      CommonResponseType & { data: any },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, type, query }) => {
        let url = END_POINTS.beneficiary;
        const queryParams = [];
        if (page) {
          queryParams.push("wpagination=true");
        }
        // if (query) {
        //   queryParams.push(`search=${query}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getBeneficiaryById: builder.query<
      CommonResponseType & { data: any },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.beneficiary}/${id}`,
        method: "GET",
      }),
    }),

    changeBeneficiaryStatus: builder.query<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.changeUserStatus}/${id}`,
        method: "GET",
      }),
    }),

    editBeneficiaryById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.beneficiary}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteBeneficiaryById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.beneficiary}/${id}`,
        method: "DELETE",
      }),
    }),

    addBeneficiary: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.beneficiary,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetBeneficiaryQuery,
  useLazyGetBeneficiaryByIdQuery,
  useEditBeneficiaryByIdMutation,
  useAddBeneficiaryMutation,
  useDeleteBeneficiaryByIdMutation,
} = userApi;
