import { Modal, Button, Checkbox } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import React from "react";
import { Review } from "../types/review";
import { useEditReviewByIdMutation } from "../services/review";
import { showToast, showError, showWarning } from "../constants/toasts";
import { CommonBody } from "../types/General";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { isValidEmail } from "../utils/validations";
import { Star } from "@mui/icons-material";

interface EditReviewModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedData: Review;
  getData: any;
  setIsMock: Dispatch<SetStateAction<boolean>>;
}

export default function EditReview({
  open,
  onClose,
  setOpen,
  selectedData,
  getData,
  setIsMock,
}: EditReviewModalProps) {
  const [update] = useEditReviewByIdMutation();
  const updateData = async () => {
    const body = data;
    console.log("body :", body);
    try {
      const encryptedData = generateEncryptedKeyBody(body) as CommonBody;
      const response = await update({
        id: `${selectedData?._id}`,
        body: encryptedData,
      }).unwrap();
      if (response?.statusCode == 200) {
        showToast("Review Updated Successfully");
        getData();
        setIsMock((prev) => !prev);
        setOpen(false);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error);
    }
  };
  const [data, setData] = useState({
    firstName: selectedData?.firstName as string,
    lastName: selectedData?.lastName as string,
    email: selectedData?.email as string,
    feedback: selectedData?.feedback as string,
    commitmentToDeliver: selectedData?.commitmentToDeliver as number,
    overallExperienceWithTraveller:
      selectedData?.overallExperienceWithTraveller as number,
    qualityOfShipmentContent: selectedData?.qualityOfShipmentContent as number,
    receivedOnTime: selectedData?.receivedOnTime as boolean,
    isFake: selectedData?.isFake as boolean,
  });
  console.log("data :", data);
  console.log("selectedData :", selectedData);
  useLayoutEffect(() => {
    if (selectedData) {
      setData({
        firstName: selectedData?.firstName as string,
        lastName: selectedData?.lastName as string,
        email: selectedData?.email as string,
        feedback: selectedData?.feedback as string,
        commitmentToDeliver: selectedData?.commitmentToDeliver as number,
        overallExperienceWithTraveller:
          selectedData?.overallExperienceWithTraveller as number,
        qualityOfShipmentContent:
          selectedData?.qualityOfShipmentContent as number,
        receivedOnTime: selectedData?.receivedOnTime as boolean,
        isFake: selectedData?.isFake as boolean,
      });
      console.log(selectedData);
    }
  }, [open]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (data?.email.trim() == "") {
      showError("Email is required");
      return;
    }
    if (!isValidEmail(data?.email)) {
      showError("Invalid email address");
      return;
    }
    if (data?.firstName.trim() == "") {
      showError("First Name is required");
      return;
    }
    if (data?.feedback.trim() == "") {
      showError("Feedback is required");
      return;
    }
    updateData();
  };

  return (
    <Modal
      className="modal editReview_modal"
      id="editReviewModal"
      aria-labelledby="editReview-modal-title"
      aria-describedby="editReview-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Edit Your Rating</h2>
            <p>Select your duration date</p>
          </div>
          <form
            className="form"
            style={{ padding: "20px" }}
            onSubmit={handleSubmit}
          >
            <div className="gap_p">
              <div className="control_group w_50">
                <label>First Name</label>
                <TextField
                  variant="outlined"
                  placeholder="Enter First Name"
                  fullWidth
                  hiddenLabel
                  value={data?.firstName}
                  onChange={(e) => {
                    setData((prev) => ({
                      ...prev,
                      firstName: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="control_group w_50">
                <label>Last Name</label>
                <TextField
                  variant="outlined"
                  placeholder="Enter Last Name"
                  fullWidth
                  hiddenLabel
                  value={data?.lastName}
                  onChange={(e) => {
                    setData((prev) => ({ ...prev, lastName: e.target.value }));
                  }}
                />
              </div>
              <div className="control_group w_50">
                <label>Email</label>
                <TextField
                  variant="outlined"
                  placeholder="Enter Email"
                  fullWidth
                  hiddenLabel
                  value={data?.email}
                  onChange={(e) => {
                    setData((prev) => ({ ...prev, email: e.target.value }));
                  }}
                />
              </div>

              <div className="control_group w_50">
                <label>Overall Experience With Traveler</label>
                <div className="control_group">
                  <Rating
                    name="size-large"
                    value={data.overallExperienceWithTraveller}
                    className="svg_rate"
                    precision={1}
                    onChange={(event, newValue) => {
                      setData((prev: any) => ({
                        ...prev,
                        overallExperienceWithTraveller: newValue,
                      }));
                    }}
                    size="large"
                    // onChangeActive={(event, newHover) => {
                    //     setHover(newHover);
                    // }}
                    emptyIcon={
                      <Star style={{ opacity: 0.55 }} fontSize="large" />
                    }
                  />
                </div>
              </div>
              <div className="control_group w_50">
                <label>Quality Of Shipment Content</label>
                <div className="control_group">
                  <Rating
                    name="size-large"
                    value={data.qualityOfShipmentContent}
                    className="svg_rate"
                    precision={1}
                    onChange={(event, newValue) => {
                      setData((prev: any) => ({
                        ...prev,
                        qualityOfShipmentContent: newValue,
                      }));
                    }}
                    size="large"
                    // onChangeActive={(event, newHover) => {
                    //     setHover(newHover);
                    // }}
                    emptyIcon={
                      <Star style={{ opacity: 0.55 }} fontSize="large" />
                    }
                  />
                </div>
              </div>

              <div className="control_group w_50">
                <label>Commitment To Deliver</label>
                <div className="control_group">
                  <Rating
                    name="size-large"
                    value={data.commitmentToDeliver}
                    className="svg_rate"
                    precision={1}
                    onChange={(event, newValue) => {
                      setData((prev: any) => ({
                        ...prev,
                        commitmentToDeliver: newValue,
                      }));
                    }}
                    size="large"
                    // onChangeActive={(event, newHover) => {
                    //     setHover(newHover);
                    // }}
                    emptyIcon={
                      <Star style={{ opacity: 0.55 }} fontSize="large" />
                    }
                  />
                </div>
              </div>

              <div
                className="control_group w_50"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  marginTop: "45px",
                }}
              >
                <div className="control_group">
                  <Checkbox
                    id="checkbox"
                    value={data?.receivedOnTime}
                    onChange={(e) => {
                      setData((prev) => ({
                        ...prev,
                        receivedOnTime: !data.receivedOnTime,
                      }));
                    }}
                  />
                </div>
                <label
                  className="prevent-select"
                  htmlFor="checkbox"
                  style={{ cursor: "pointer", marginBottom: 0 }}
                >
                  Received On Time
                </label>
              </div>
              <div className="control_group w_100" style={{ height: "200px" }}>
                <label>Feedback</label>
                <TextField
                  variant="outlined"
                  placeholder="Feedback"
                  fullWidth
                  hiddenLabel
                  multiline
                  value={data.feedback}
                  onChange={(newValue) => {
                    setData((prev: any) => ({
                      ...prev,
                      feedback: newValue.target.value,
                    }));
                  }}
                  maxRows={5}
                  minRows={5}
                />
              </div>
            </div>
            <div className="form_btn end">
              <Button className="btn btn_secondary" type="submit">
                Submit Review
              </Button>
            </div>
          </form>
          {/* <form className="form" action="">
            <div className="control_group">
              <Rating
                name="hover-feedback"
                value={value}
                precision={0.5}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
            </div>
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Name"
                fullWidth
                hiddenLabel
                value={userData?.name}
                onChange={(e) =>
                  setUserData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </div>
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Email"
                fullWidth
                hiddenLabel
                value={userData?.email}
                onChange={(e) =>
                  setUserData((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </div>
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Description"
                fullWidth
                hiddenLabel
                multiline
                maxRows={5}
                minRows={5}
                value={userData?.description}
                onChange={(e) =>
                  setUserData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </div>
            <div className="footer_btn_flex">
              <Button
                className="btn btn_highlight"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="btn btn_secondary"
                onClick={() => {
                  if (userData?.email.trim() == "") {
                    showError("Email is required");
                    return;
                  }
                  if (!isValidEmail(userData?.email)) {
                    showError("Invalid email address");
                    return;
                  }
                  if (userData?.name.trim() == "") {
                    showError("Name is required");
                    return;
                  }
                  if (userData?.description.trim() == "") {
                    showError("Description is required");
                    return;
                  }
                  updateData();
                }}
              >
                Save Changes
              </Button>
            </div>
          </form> */}
        </div>
      </div>
    </Modal>
  );
}
