import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

interface UserRatingModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function UserRating({
  open,
  onClose,
  setOpen,
}: UserRatingModalProps) {
  const value1 = 4.5;

  return (
    <Modal
      className="modal userRating_modal"
      id="userRatingModal"
      aria-labelledby="userRating-modal-title"
      aria-describedby="userRating-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <figure>
            <img src="/static/images/user_attachmen2.jpg" alt="User" />
          </figure>
          <h2>Emma smith</h2>
          <p>5 days ago</p>
          <Rating
            name="read-only"
            value={value1}
            readOnly
            precision={0.5}
            emptyIcon={<StarIcon />}
          />
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
        </div>
      </div>
    </Modal>
  );
}
