export const travelTypeImages: Record<number, string> = {
  1: "/static/images/travel/car.png",
  2: "/static/images/travel/aeroplanIcon.png",
  3: "/static/images/travel/voyage.png",
  4: "/static/images/travel/train.png",
  5: "/static/images/travel/busIcon.png",
};
export const getTravelTypeName = (travelType: number) => {
  if (travelType == 1) {
    return "Private Vehicle";
  } else if (travelType == 2) {
    return "Airlines";
  } else if (travelType == 3) {
    return "Voyage";
  } else if (travelType == 4) {
    return "Railway";
  } else if (travelType == 5) {
    return "Public Road";
  } else {
    return "";
  }
};
