import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface ViewCommentModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  comment: string[];
}

export default function ViewComment({
  open,
  onClose,
  setOpen,
  comment,
}: ViewCommentModalProps) {
  return (
    <Modal
      className="modal viewComment_modal"
      id="viewCommentModal"
      aria-labelledby="viewComment-modal-title"
      aria-describedby="viewComment-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Comment</h2>
            {comment?.map((data, index) => (
              <p>{`${index + 1}. ${data}`}</p>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
