import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface ComplaintDetailModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: string;
  value: string;
}

export default function ComplaintDetail({
  open,
  onClose,
  setOpen,
  data,
  value,
}: ComplaintDetailModalProps) {
  return (
    <Modal
      className="modal complaintDetail_modal"
      id="complaintDetailModal"
      aria-labelledby="complaintDetail-modal-title"
      aria-describedby="complaintDetail-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>{value} Detail</h2>
            <p>{`${value} given by user`}</p>
          </div>
          <div className="complaint_reason">
            <p>{data ? data : `No ${value}`}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
