import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { font } from "../font/Amiri"; // Import the converted font file

export const exportToPdf = (rows: any, columns: any, heading?: string) => {
  const fieldsToIgnore = [
    "passportId",
    "ticketVehicleReg",
    "action",
    "viewDetail",
  ];
  const doc = new jsPDF({
    orientation: "landscape",
  });

  // Add and set the Amiri font
  doc.addFileToVFS("Amiri-Regular.ttf", font);
  doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");

  // Set default font (Latin)
  doc.setFont("Amiri");

  // Add heading text (check for Arabic content)
  const isArabic = /[\u0600-\u06FF]/.test(heading || "");
  if (isArabic) {
    doc.setFont("Amiri");
  } else {
    doc.setFont("helvetica");
  }
  doc.text(heading ? `${heading} : ` : "GinaEx : ", 20, 10);

  // Prepare columns and rows for the table
  const filteredColumns = columns
    ?.filter((data: any) => !fieldsToIgnore.includes(data?.field))
    .map((col: any) => ({
      ...col,
      title: col?.headerName,
      dataKey: col?.field,
    }));

  // Create the table with mixed language support
  autoTable(doc, {
    theme: "grid",
    headStyles: { fillColor: "#4E148C" },
    columns: filteredColumns,
    body: rows.map((row: any) =>
      filteredColumns.map((col: any) => {
        const cellText = row[col.dataKey] || "";
        const isCellArabic = /[\u0600-\u06FF]/.test(cellText);
        return {
          content: cellText,
          styles: { font: isCellArabic ? "Amiri" : "helvetica" },
        };
      })
    ),
  });

  // Save the PDF
  doc.save("Gina Admin.pdf");
};
