/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import TableComponent from "../../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import FreightCustomerDetail from "../../../Modals/freightCustomerDetail";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../../constants/role";
import { NewOrder } from "../../../types/newOrder";
import { showError, showToast } from "../../../constants/toasts";
import {
  ORDER_ACCEPT_STATUS,
  ORDER_TRACKING_STATUS,
} from "../../../constants/shipmentOrder";
import { useEditTravelerByIdMutation } from "../../../services/travelers";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { useEditNewOrderByIdMutation } from "../../../services/shipmentTrack";

const WaitingOrdersList = ({
  columnVisibilityModel,
  newOrders,
  setSelectedRow,
  gridApi,
  getDataList,
}: {
  columnVisibilityModel: any;
  newOrders: NewOrder[];
  setSelectedRow: any;
  gridApi: any;
  getDataList: any;
}) => {
  const navigate = useNavigate();

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [shipmentDetails, setShipmentDetails] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [updateData, test] = useEditNewOrderByIdMutation();
  console.log("TEST", test);

  const handleStatusChange = async (id: any, adminStatus: any) => {
    try {
      const body = {
        status: adminStatus,
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateData({
        id: `${id}`,
        body: encryptedData,
      }).unwrap();
      showToast("Shipment order status updated successfully");
      getDataList();
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  const rows: any = [
    // {
    //     id: 1,
    //     dateOfOrder: "06 Jan 2024",
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
    // {
    //     id: 2,
    //     dateOfOrder: "06 Jan 2024",
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
    // {
    //     id: 3,
    //     dateOfOrder: "06 Jan 2024",
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
    // {
    //     id: 4,
    //     dateOfOrder: "06 Jan 2024",
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
    // {
    //     id: 5,
    //     dateOfOrder: "06 Jan 2024",
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
  ];
  newOrders?.map((data: NewOrder) => {
    rows.push({
      id: data?._id,
      // travelers:data?.traveller?.fullName,
      // travelerImage:data?.traveller?.personVerification?.profileImage,
      // travelerId:data?.traveller._id,
      // travelerMobile:data?.traveller?.countryCode+data?.traveller?.phone,
      freightCustomer:
        data?.customer?.firstName + " " + data?.customer?.lastName,
      freightCustomerImage: data?.customer?.personVerification?.profileImage,
      freightCustomerId: data?.customer._id,
      freightCustomerMobile:
        data?.customer?.countryCode + data?.customer?.phone,
      shipmentNo: data?.shipmentId?.shipmentId
        ? "SH" + data?.shipmentId?.shipmentId
        : "-",
      currentCity: data?.beneficiaryDetail?.customerCurrentCity
        ? data?.beneficiaryDetail?.customerCurrentCity
        : "-",
      destinationCity: data?.beneficiaryDetail?.city
        ? data?.beneficiaryDetail?.city
        : "-",
      dateOfOrder: data?.createdAt?.slice(0, 10),
      // expectingDeliveryDate:increaseDays(data?.createdAt,2)?.slice(0,10),
      // dateOfAcceptance:data?.acceptanceDate?.slice(0,10),
      // departureDate:data?.travelShipmentId?.travelDetail?.[0]?.departureDate?.slice(0,10),
      // arrivedDate:data?.travelShipmentId?.travelDetail?.[data?.travelShipmentId?.travelDetail?.length-1]?.arrivalDate?.slice(0,10),
      shipmentDetails: {
        packageDetail: data?.packageDetail,
      },
      // delayDepartureDate:data?.delayDepartureDate?.slice(0,10),
      offers: data?.packageDetail?.length
        ? `${data?.packageDetail?.reduce(
            (accumulator: any, currentValue: any) => {
              return accumulator + currentValue?.isCancelled
                ? 0
                : currentValue?.amount;
            },
            0
          )} ${data?.orderCurrency?.currencyName} (${data?.packageDetail
            ?.reduce((accumulator: any, currentValue: any) => {
              return accumulator + currentValue?.isCancelled
                ? 0
                : currentValue?.amountUsd;
            }, 0)
            ?.toFixed(2)} $)`
        : "0",
      // viewRemark:data?.remarks
      status: data?.status == 1 ? "New" : "Closed",
      statusEnum: data?.status,
    });
  });
  const user = useAuth();
  // const columns: GridColDef<(typeof rows)[number]>[] = [
  //     {
  //         flex: 1,
  //         minWidth: 220,
  //         field: "freightCustomers",
  //         headerName: "Freight Customers",
  //         editable: false,
  //         renderCell: (data:any) => {
  //             return (
  //                 <div className="user_block">
  //                     <figure onClick={() =>
  //                       ((user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isView)? navigate("/profile-detail/"+data?.row?.userId) : null)
  //                         }>
  //                         <img src="/static/images/user_attachment.jpg" alt="" />
  //                     </figure>
  //                     <p className="cursor_p" onClick={() => setOpenModal(true)}>
  //                         <b>Michael Johnson</b> +963024587126
  //                     </p>
  //                 </div>
  //             );
  //         },
  //     },
  //     {
  //         flex: 1,
  //         minWidth: 140,
  //         field: "dateOfOrder",
  //         headerName: "Date of order",
  //         editable: false,
  //     },
  //     {
  //         flex: 1,
  //         minWidth: 120,
  //         field: "shipmentNo",
  //         headerName: "Shipment No",
  //         editable: false,
  //         sortable: false,
  //     },
  //     {
  //         flex: 1,
  //         minWidth: 120,
  //         field: "currentCity",
  //         headerName: "Current City",
  //         editable: false,
  //     },
  //     {
  //         flex: 1,
  //         minWidth: 150,
  //         field: "destinationCity",
  //         headerName: "Destination City",
  //         editable: false,
  //     },
  //     {
  //         flex: 1,
  //         minWidth: 120,
  //         field: "status",
  //         headerName: "Status",
  //         editable: false,
  //         sortable: false,
  //         renderCell: () => {
  //             return (
  //                 <Button className="btn_text c_success">New</Button>
  //             );
  //         },
  //     },
  //     {
  //         flex: 1,
  //         minWidth: 80,
  //         field: "action",
  //         headerName: "Action",
  //         editable: false,
  //         sortable: false,
  //         renderCell: () => {
  //             return (

  //             );
  //         },
  //     },
  // ];
  const ActionData = ({ data }: any) => {
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
      setAnchorEl1(null);
    };
    return (
      <div className="table_actions">
        <Box>
          <IconButton
            aria-controls={open1 ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick1}
          >
            <img src="/static/images/more_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu"
            id="basic-menu1"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {data?.row?.statusEnum == 2 ? (
              <MenuItem onClick={() => handleStatusChange(data?.row?.id, 1)}>
                New
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleStatusChange(data?.row?.id, 2)}>
                Close
              </MenuItem>
            )}
          </Menu>
        </Box>
      </div>
    );
  };
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 200,
      field: "freightCustomer",
      headerName: "Freight Customers",
      editable: false,
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + data?.row?.freightCustomerId)
                  : null
              }
            >
              <img
                src={
                  data?.row?.freightCustomerImage
                    ? data?.row?.freightCustomerImage
                    : "/static/images/user_placeholder.png"
                }
                alt=""
              />
            </figure>
            <p>
              <b>{data?.row?.freightCustomer}</b>{" "}
              {data?.row?.freightCustomerMobile}
            </p>
          </div>
        );
      },
    },
    // {
    //     flex: 1,
    //     minWidth: 180,
    //     field: "travelers",
    //     headerName: "Travelers",
    //     editable: false,
    //     renderCell: (data:any) => {
    //         return (
    //             <div className="user_block">
    //                 <figure onClick={() =>
    //                     ((user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isView)? navigate("/profile-detail/"+data?.row?.userId) : null)
    //                     }>
    //                     <img src="/static/images/user_attachment.jpg" alt="" />
    //                 </figure>
    //                 <p>
    //                     <b>Emma smith</b> +963024587126
    //                 </p>
    //             </div>
    //         );
    //     },
    // },
    {
      flex: 1,
      minWidth: 120,
      field: "shipmentNo",
      headerName: "Shipment No",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "currentCity",
      headerName: "Current City",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 180,
      field: "destinationCity",
      headerName: "Destination City",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "offers",
      headerName: "Offers",
      editable: false,
      sortable: false,
    },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "contact",
    //     headerName: "Contact",
    //     editable: false,
    //     sortable: false,
    // },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "email",
    //     headerName: "Email Address",
    //     editable: false,
    //     sortable: false,
    // },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "dateOfAcceptance",
    //     headerName: "Date Of Acceptance",
    //     editable: false,
    //     sortable: false,
    // },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "viewRemark",
    //     headerName: "View Remark",
    //     editable: false,
    //     sortable: false,
    // },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "expectingDeliveryDate",
    //     headerName: "Expecting Delivery Date",
    //     editable: false,
    //     sortable: false,
    // },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "arrivedDate",
    //     headerName: "Arrived Date",
    //     editable: false,
    //     sortable: false,
    // },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "delayDepartureDate",
    //     headerName: "Delay Departure Date",
    //     editable: false,
    //     sortable: false,
    // },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "departureDate",
    //     headerName: "Departure Date",
    //     editable: false,
    //     sortable: false,
    // },
    {
      flex: 1,
      minWidth: 150,
      field: "dateOfOrder",
      headerName: "Date Of Order",
      editable: false,
      sortable: false,
    },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "viewDetail",
    //     headerName: "View Detail",
    //     editable: false,
    //     sortable: false,
    //     renderCell: (data:any) => {
    //         return (
    //             <p className="view_link" onClick={() => {
    //                 if(!data?.row?.shipmentDetails?.packageDetail)
    //                     {
    //                         showError("There is no additional data for this order")
    //                         return;
    //                     }
    //                 setShipmentDetails(data?.row?.shipmentDetails)
    //                 setOpenModal(true)}}>View detail</p>
    //         );
    //     },
    // },
    {
      flex: 1,
      minWidth: 120,
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: false,
      renderCell: (data) => {
        return (
          <>
            {data?.row?.statusEnum == 1 && (
              <Button className="btn_text c_success">New</Button>
            )}
            {data?.row?.statusEnum == 2 && (
              <Button className="btn_text c_black">Closed</Button>
            )}
          </>
        );
      },
    },
    {
      flex: 1,
      minWidth: 80,
      field: "action",
      headerName: "Action",
      editable: false,
      sortable: false,
      renderCell: (data) => {
        return <ActionData data={data} />;
      },
    },
  ];
  let hidden = ["fullName"];

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          setSelectedRow={setSelectedRow}
          gridApi={gridApi}
          columns={columns}
          rows={rows}
          columnVisibilityModel={columnVisibilityModel}
        />
      </div>

      <FreightCustomerDetail
        open={openModal}
        onClose={handleCloseModal}
        setOpen={setOpenModal}
        shipmentDetails={shipmentDetails}
      />
    </>
  );
};

export default WaitingOrdersList;
