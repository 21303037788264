import { Button, Modal, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { showToast } from "../constants/toasts";

interface ViewRemarkModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleStatusChange: any;
  reason: any;
  setReason: any;
}

export default function AdminRemark({
  open,
  onClose,
  setOpen,
  handleStatusChange,
  reason,
  setReason,
}: ViewRemarkModalProps) {
  const [error, setError] = useState(false);
  return (
    <Modal
      className="modal remark_modal"
      id="ViewRemarkModal"
      aria-labelledby="ViewRemark-modal-title"
      aria-describedby="ViewRemark-modal-description"
      open={open}
      onClose={() => {
        setReason("");
        setError(false);
        onClose();
      }}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Remark</h2>
          </div>
          <div className="remark_detail" style={{ textAlign: "center" }}>
            <div className="user_block" style={{ display: "block" }}>
              <Typography textAlign={"start"} marginBottom={"5px"}>
                Enter Remarks
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Enter reason"
                fullWidth
                hiddenLabel
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                name="fullName"
              />
              {error && !reason ? (
                <h6
                  className="err_msg"
                  style={{
                    textAlign: "start",
                    marginLeft: "5px",
                    marginTop: "5px",
                  }}
                >
                  Remarks are required
                </h6>
              ) : (
                ""
              )}
            </div>
            <div style={{ textAlign: "end" }}>
              <Button
                className="btn btn_secondary"
                type="submit"
                onClick={() => {
                  setError(true);
                  if (!reason) {
                    return;
                  }
                  handleStatusChange();
                  setReason("");
                  setError(false);
                  setOpen(false);
                  onClose();
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
