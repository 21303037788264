/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import TableComponent from "../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";

const LookingForTravellersList = ({ travelers }: any) => {
  const navigate = useNavigate();

  const value1 = 5;
  const getTravelTypeName = (travelType: number) => {
    if (travelType == 1) {
      return "Private Vehicle";
    } else if (travelType == 2) {
      return "Airlines";
    } else if (travelType == 3) {
      return "Voyage";
    } else if (travelType == 4) {
      return "Railway";
    } else if (travelType == 5) {
      return "Public Road";
    } else {
      return "";
    }
  };
  const rows: any = [];
  travelers?.map((data: any) => {
    rows.push({
      id: data?._id,
      name: data?.userDetails?.[0]?.fullName,
      TR: data?.shipmentId,
      availableWeight: data?.travelDetail?.[0]?.availableWeight?.toString(),
      departureCityDate:
        data?.travelDetail?.[0]?.departingFrom +
        " /" +
        data?.travelDetail?.[0]?.departureDate?.slice(0, 10),
      arrivalCityDate:
        data?.travelDetail?.[data?.travelDetail?.length - 1]?.destination +
        "/" +
        data?.travelDetail?.[
          data?.travelDetail?.length - 1
        ]?.arrivalDate?.slice(0, 10),
      mobile:
        data?.userDetails?.[0]?.countryCode + data?.userDetails?.[0]?.phone,
      phone: data?.userDetails?.[0]?.phone,
      countryCode: data?.userDetails?.[0]?.countryCode,
      email: data?.userDetails?.[0]?.email,
      approveDate: data?.updatedAt?.slice(0, 10),
      currentCity: data?.currentAddress?.city,
      wayOfTravel: getTravelTypeName(data?.travelType),
      image: data?.personVerification?.profileImage,
      isBlocked: data?.isBlocked,
      status: data?.isBlocked ? "Disabled" : "Enabled",
      departingDate: data?.travelDetail?.[0]?.departureDate,
      arrivalDate:
        data?.travelDetail?.[data?.travelDetail?.length - 1]?.arrivalDate,
      paymentMethod:
        data?.paymentType == 1 ? "50 % Advance" : "Full on delivery",
      volunteer: data?.drugsVolunteer ? "Yes" : "No",
      ETADate:
        data?.travelDetail?.[data?.travelDetail?.length - 1]?.arrivalDate,
      ATDDate: data?.travelDetail?.[0]?.departureDate,
      userId: data?.userDetails?.[0]?._id,
      rating: data?.userDetails?.[0]?.avgRating,
    });
  });
  const user = useAuth();
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 190,
      field: "name",
      headerName: "Name",
      editable: false,
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + data?.row?.userId)
                  : null
              }
            >
              <img
                src={
                  data?.row?.image
                    ? data?.row?.image
                    : "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <Tooltip
              title={
                <p style={{ color: "white" }}>
                  {data?.row?.name}
                  <br />
                  {data?.row?.mobile}
                  <br />
                  {data?.row?.email}
                </p>
              }
            >
              <p className="cursor_p">
                <b>{data?.row?.name}</b>
                {data?.row?.mobile}
                <br />
                {data?.row?.email}
              </p>
            </Tooltip>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: "review",
      headerName: "Review",
      editable: false,
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <p>
            Rating <br />
            <Rating
              name="read-only"
              value={row?.rating}
              readOnly
              precision={0.5}
              emptyIcon={<StarIcon />}
            />
          </p>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "ATDDate",
      headerName: "ATD & Date",
      editable: false,
      renderCell: (data: any) => {
        return (
          <p>
            {data?.row?.departingDate?.split("T")?.[0]} <br /> at{" "}
            {data?.row?.departingDate?.split("T")?.[1].slice(0, 5)}
          </p>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "ETADate",
      headerName: "ETA & Date",
      editable: false,
      renderCell: (data: any) => {
        return (
          <p>
            {data?.row?.arrivalDate?.split("T")?.[0]} <br /> at{" "}
            {data?.row?.arrivalDate?.split("T")?.[1].slice(0, 5)}
          </p>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "volunteer",
      headerName: "Volunteer",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 120,
      field: "wayOfTravel",
      headerName: "Way of travel",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 100,
      field: "availableWeight",
      headerName: "Available Weight",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 60,
      field: "paymentMethod",
      headerName: "Payment Method",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  ];

  let hidden = ["fullName"];

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          columns={columns?.filter((item) => !hidden.includes(item.field))}
          rows={rows}
        />
      </div>
    </>
  );
};

export default LookingForTravellersList;
