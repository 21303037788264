import {
  Modal,
  Button,
  IconButton,
  Input,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { showError, showToast } from "../constants/toasts";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../helpers";
import { useAppDispatch } from "../hooks/store";
import { usePutUpdateProfileMutation } from "../services/profile";
import { UploadMedia } from "../utils/uploadMedia";
import PhoneInput from "react-phone-input-2";
import { setCredentials } from "../reducers/authSlice";
import { CommonBody } from "../types/General";
import { generateEncryptedKeyBody } from "../utils/crypto";
import {
  useAddSubAdminMutation,
  useEditSubAdminByIdMutation,
} from "../services/subAdmin";
import { boolean } from "yup";
import { Check, Close } from "@mui/icons-material";

interface AddUserModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  roles?: any;
  getSubAdminsList?: any;
  selectedData?: any;
}

export default function ViewUser({
  open,
  onClose,
  setOpen,
  roles,
  getSubAdminsList,
  selectedData,
}: AddUserModalProps) {
  const [selectedRole, setSelectedRole] = useState("");
  console.log("selectedData :", selectedData);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const user = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [updateprofile]: any = usePutUpdateProfileMutation();
  const [phone, setPhone] = useState("+91");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [profile_picture, setProfilePicture] = useState<any>(null);
  const [imageId, setImageId] = useState(null);
  const [phoneDisplay, setPhoneDisplay] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [addSubAdmin] = useAddSubAdminMutation();
  const [editSubAdminById] = useEditSubAdminByIdMutation();

  // console.log("TTTT",selectedData);

  const [isDisplayRole, setIsDisplayRole] = useState<boolean>(true);
  useEffect(() => {
    if (selectedData) {
      setPhone(selectedData?.phone);
      setPhoneCode(selectedData?.countryCode);
      setPhoneDisplay(selectedData?.countryCode + selectedData?.phone);
      setEmail(selectedData?.email);
      setFullName(selectedData?.name);
      setProfilePicture(selectedData?.image);
      setSelectedRole(selectedData?.roleId);
      setIsDisplayRole(selectedData?.roleType == 3);
    }
  }, [open]);

  // console.log("user ROLE IS 2 :"+(user?.role==2) ,"IS EDIT :" +isEdit)
  return (
    <Modal
      className="modal addUser_modal addRole_modal2"
      id="addUserModal"
      aria-labelledby="addUser-modal-title"
      aria-describedby="addUser-modal-description"
      open={open}
      onClose={() => {
        setError(false);
        setOpen(false);
        setFullName("");
        setEmail("");
        setPhone("");
        setEmail("");
        setProfilePicture("");
        setPhoneCode("");
        setPhone("");
        setPhoneDisplay("");
        setIsDisplayRole(true);
        onClose();
      }}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>{"User Details"}</h2>
          </div>
          <form className="form" action="">
            <div className="gap_p">
              <div className="control_group w_100">
                <div className="upload_box">
                  <label>
                    <figure>
                      <img
                        src={
                          profile_picture
                            ? profile_picture
                            : "/static/images/user-placeholder.jpg"
                        }
                        alt="Upload"
                      />
                    </figure>
                  </label>
                </div>
              </div>
              <div className="control_group w_50">
                <TextField
                  variant="outlined"
                  placeholder="Full name"
                  fullWidth
                  disabled
                  hiddenLabel
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                {error && fullName == "" ? (
                  <h6 className="err_msg">Name is required</h6>
                ) : (
                  ""
                )}
              </div>
              <div className="control_group w_50">
                <TextField
                  variant="outlined"
                  placeholder="Email address"
                  fullWidth
                  hiddenLabel
                  disabled
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error && email == "" ? (
                  <h6 className="err_msg">Email is required</h6>
                ) : (
                  ""
                )}
              </div>
              <div className="control_group w_100">
                <PhoneInput
                  disabled
                  country={"us"}
                  placeholder="0 (000) 000-000"
                  enableSearch={true}
                  inputStyle={{ width: "100%" }}
                  value={phoneDisplay}
                  // onChange={(value) => formik.setFieldValue("phone", value)}
                  //   onChange={(phone, country) =>
                  //     handleChangePhone(phone, country)
                  //   }
                />
                {error && phone == "" ? (
                  <h6 className="err_msg">Phone is required</h6>
                ) : (
                  ""
                )}
              </div>
              <div>Role Name : {selectedData?.role}</div>
            </div>
            {isDisplayRole && (
              <>
                <div className="user_view_label">
                  <h3>Permissions : </h3>
                  <h4>Read</h4>
                  <h4>Create/Edit</h4>
                  <h4>Delete</h4>
                </div>
                <ul className="roles_list">
                  {selectedData?.permissions?.map(
                    (data: any, index: number) => (
                      <li>
                        <div className="user_view_label">
                          <p
                            style={{
                              fontSize: "12px",
                              padding: 0,
                              position: "absolute",
                              left: "40px",
                            }}
                          >
                            {data.label}
                          </p>
                          <h4 style={{ position: "absolute", left: "250px" }}>
                            {data?.isView ? (
                              <Check htmlColor="green" />
                            ) : (
                              <Close htmlColor="red" />
                            )}
                          </h4>
                          <h4 style={{ position: "absolute", right: "195px" }}>
                            {data?.isEdit ? (
                              <Check htmlColor="green" />
                            ) : (
                              <Close htmlColor="red" />
                            )}
                          </h4>
                          <h4 style={{ position: "absolute", right: "75px" }}>
                            {data?.isDelete ? (
                              <Check htmlColor="green" />
                            ) : (
                              <Close htmlColor="red" />
                            )}
                          </h4>
                        </div>
                        {/* <FormGroup color="#000">
                          <FormControlLabel
                            color="#000"
                            control={<Checkbox checked={data.isView} />}
                            label="Read"
                          />
                          <FormControlLabel
                            color="#000"
                            control={<Checkbox checked={data.isEdit} />}
                            label="Create/Edit"
                          />
                          <FormControlLabel
                            color="#000"
                            control={<Checkbox checked={data.isDelete} />}
                            label="Delete"
                          />
                        </FormGroup> */}
                      </li>
                    )
                  )}
                </ul>
              </>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
}
