/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";

const Input = styled(MuiInput)`
  width: 60px;
`;

const ProfileTop = ({ userData }: any) => {
  console.log("userData :", userData);
  const [value1, setValue1] = React.useState(userData?.onTimeDelivery);
  const handleSliderChange1 = (event: Event, newValue1: number | number[]) => {
    setValue1(newValue1 as number);
  };
  const handleInputChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue1(event.target.value === "" ? 0 : Number(event.target.value));
  };
  const handleBlur1 = () => {
    if (value1 < 0) {
      setValue1(0);
    } else if (value1 > 100) {
      setValue1(100);
    }
  };

  const [value2, setValue2] = React.useState(userData?.DeliverQuality);
  const handleSliderChange2 = (event: Event, newValue2: number | number[]) => {
    setValue2(newValue2 as number);
  };
  const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue2(event.target.value === "" ? 0 : Number(event.target.value));
  };
  const handleBlur2 = () => {
    if (value2 < 0) {
      setValue2(0);
    } else if (value2 > 100) {
      setValue2(100);
    }
  };
  useEffect(() => {
    setValue1(userData?.onTimeDelivery || 0);
    setValue2(userData?.DeliverQuality || 0);
  }, []);
  return (
    <>
      <div className="cards profile_top">
        <div className="gap_p">
          <div className="left_s">
            <div className="profile_image">
              <figure>
                <img
                  src={
                    userData?.user?.personVerification?.profileImage
                      ? userData?.user?.personVerification?.profileImage
                      : "/static/images/user-placeholder.jpg"
                    // "/static/images/profile_attachment.jpg"
                  }
                  alt="Profile"
                />
                <span>
                  <img src="/static/images/verified_icon.svg" alt="Icon" />
                </span>
              </figure>
              <p>
                {userData?.memberShipId
                  ? "GN" + userData?.memberShipId
                  : "GN12345667"}
              </p>
            </div>
            <div className="profile_info">
              <h2>
                <b>
                  {userData?.user?.firstName
                    ? userData?.user?.firstName + " " + userData?.user?.lastName
                    : userData?.user?.fullName}
                </b>
                <span>
                  <StarIcon />
                  {userData?.rating ? userData?.rating : "0"} Stars (
                  {userData?.ratingCount ? userData?.ratingCount : "0"})
                </span>
              </h2>
              <ul className="text_list">
                <li>
                  <img src="/static/images/sms_icon.svg" alt="Icon" />
                  {userData?.user?.email ? userData?.user?.email : "-"}
                </li>
                <li>
                  <img src="/static/images/calling_icon.svg" alt="Icon" />{" "}
                  {userData?.user?.phone
                    ? userData?.user?.countryCode + " " + userData?.user?.phone
                    : "-"}
                </li>
                <li>
                  <img src="/static/images/users_icon.svg" alt="Icon" />{" "}
                  {userData?.user?.gender
                    ? userData?.user?.gender == "1"
                      ? "Male"
                      : "Female"
                    : "-"}
                </li>
              </ul>
              <ul className="box_list">
                <li>
                  <h3>
                    <img src="/static/images/trip_icon.svg" alt="Icon" />{" "}
                    {userData?.tripCount ? userData?.tripCount : "0"}
                  </h3>
                  <p>Number of trips</p>
                </li>
                <li>
                  <h3>
                    <img src="/static/images/orders_icon.svg" alt="Icon" />
                    {userData?.orderDelivered ? userData?.orderDelivered : "0"}
                  </h3>
                  <p>Delivered orders</p>
                </li>
                <li>
                  <h3>
                    <img src="/static/images/crown_icon.svg" alt="Icon" />{" "}
                    {userData?.drugVolunteerCount
                      ? userData?.drugVolunteerCount
                      : "0"}
                  </h3>
                  <p>Volunteers</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="right_s">
            {/* <div className="social_icons">
              <IconButton>
                <img src="/static/images/facebook_icon.svg" alt="Icon" />
              </IconButton>
              <IconButton>
                <img src="/static/images/instagram_icon.svg" alt="Icon" />
              </IconButton>
              <IconButton>
                <img src="/static/images/twitter_icon.svg" alt="Icon" />
              </IconButton>
              <IconButton>
                <img src="/static/images/youtube_icon.svg" alt="Icon" />
              </IconButton>
            </div> */}

            <div
              className="progress_info"
              style={{ margin: "50px 0px 0px 0px" }}
            >
              <h3>On time Delivery</h3>
              <div>
                <Slider
                  value={Number(userData?.onTimeDelivery)}
                  onChange={handleSliderChange1}
                  aria-labelledby="input-slider"
                />
                <Input
                  value={userData?.onTimeDelivery || 0}
                  size="small"
                  onChange={handleInputChange1}
                  onBlur={handleBlur1}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />{" "}
                %
              </div>

              <h3>Quality of deliver</h3>
              <div>
                <Slider
                  value={Number(userData?.DeliverQuality)}
                  onChange={handleSliderChange2}
                  aria-labelledby="input-slider"
                />
                <Input
                  value={userData?.DeliverQuality}
                  size="small"
                  onChange={handleInputChange2}
                  onBlur={handleBlur2}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
                %
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileTop;
