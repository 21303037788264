//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { any } from "../types/Notification";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
  statusCode: number;
  message: string;
};

type GetAllNotificationResponse = {
  count?: number;
  data: any[];
  // Notification: any[];
  notification: any;

  notificationCount: number;
};

type GetTokenParams = {
  // limit?: number;
  page?: number;
  query?: string;
};
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotification: builder.query<
      CommonResponseType & { data: GetAllNotificationResponse },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, query }) => {
        let url = END_POINTS.notification;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getAdminNotification: builder.query<
      CommonResponseType & { data: GetAllNotificationResponse },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, query }) => {
        let url = END_POINTS.adminNotification;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getNotificationsById: builder.query<
      CommonResponseType & { data: any },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.notification}/${id}`,
        method: "GET",
      }),
    }),

    changeNotificationStatus: builder.query<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.changeUserStatus}/${id}`,
        method: "GET",
      }),
    }),

    editNotificationById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.notification}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteNotificationById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.notification}/${id}`,
        method: "DELETE",
      }),
    }),

    addNotification: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.notification,
        method: "POST",
        body,
      }),
    }),
    deleteAdminNotifications: builder.mutation<
      CommonResponseType & { data: any },
      null
    >({
      query: () => ({
        url: END_POINTS.adminNotification,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useLazyGetNotificationQuery,
  //  useLazyGetNotificationsByIdQuery,
  useEditNotificationByIdMutation,
  useAddNotificationMutation,
  useDeleteNotificationByIdMutation,
  useDeleteAdminNotificationsMutation,
  useLazyGetAdminNotificationQuery,
} = userApi;
