import { Button, Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Feedback } from "../types/shipmentFeedback";

interface GiveFeedback1ModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: Feedback;
}

export default function GiveFeedback1({
  open,
  onClose,
  setOpen,
  data,
}: GiveFeedback1ModalProps) {
  const value1 = 4;

  return (
    <Modal
      className="modal giveFeedback_modal"
      id="giveFeedback1Modal"
      aria-labelledby="giveFeedback1-modal-title"
      aria-describedby="giveFeedback1-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Feedback</h2>
            <p>Feedback about the shipment</p>
          </div>

          <h3>Was the purpose of the travel</h3>
          <p>{data?.purposeOfTravel || "No purpose given"}</p>

          <h3>When was the last time of country visited ?</h3>
          <p>{data?.lastTimeVisited || "No visit"}</p>

          <h3>
            Rating experience with GinaEx
            <Rating
              name="read-only"
              value={data?.experienceWithGinaex || 0}
              readOnly
              precision={0.5}
              emptyIcon={<StarIcon />}
            />
          </h3>

          <h3>
            Recommend there friends to use the application
            <Rating
              name="read-only"
              value={data?.recommendToOthers || 0}
              readOnly
              precision={0.5}
              emptyIcon={<StarIcon />}
            />
          </h3>

          <h3>Feedback</h3>
          <p>{data?.feedback || "No feedback given"}</p>

          <div className="footer_btn_flex">
            <Button className="btn btn_primary" onClick={() => setOpen(false)}>
              OK
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
