//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllRoleResponse = {
  role: any;
  count: number;
};
type RoleResponse = {
  _id: string;
  name: string;
  image: string;
  isBlocked: string;
};
type GetTokenParams = {
  // limit?: number;
  // page?: number;
  // query?: string;
};

type EncryptedBody = {
  hash: string;
  sek: string;
} | null;
export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<CommonResponseType & { data: any }, GetTokenParams>(
      {
        // query:({page,limit,query})=>{
        query: () => {
          let url = END_POINTS.subAdminRoles;
          // const queryParams = [];
          // if (page) {
          //   queryParams.push(`page=${page}`);
          // }
          // if (query) {
          //   queryParams.push(`search=${query}`);
          // }
          // if (limit) {
          //   queryParams.push(`limit=${limit}`);
          // }
          // if (queryParams.length > 0) {
          //   url += `?${queryParams.join('&')}`;
          // }
          return {
            url: url,
            method: "GET",
          };
        },
      }
    ),
    getRoleById: builder.query<
      CommonResponseType & { data: RoleResponse },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.subAdminRoles}/${id}`,
        method: "GET",
      }),
    }),
    editRoleById: builder.mutation<
      CommonResponseType & { data: RoleResponse },
      { id: string | undefined; body: EncryptedBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.subAdminRoles}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    addRole: builder.mutation<
      CommonResponseType & { data: RoleResponse },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.subAdminRoles,
        method: "POST",
        body,
      }),
    }),

    deleteRoleById: builder.mutation<
      CommonResponseType & { data: RoleResponse },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.subAdminRoles}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetRolesQuery,
  useLazyGetRoleByIdQuery,
  useAddRoleMutation,
  useDeleteRoleByIdMutation,
  useEditRoleByIdMutation,
} = userApi;
