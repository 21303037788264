/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import TableComponent from "../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import PassportModal from "../../Modals/passportModal";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../Modals/datePicker";
import { showToast, showError } from "../../constants/toasts";
import {
  useDeleteTravelerByIdMutation,
  useEditTravelerByIdMutation,
} from "../../services/travelers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import VehicleRegistrationModal from "../../Modals/vehicleRegistration";
import { ADMIN_ROLE_ID } from "../../constants/role";
import useAuth from "../../hooks/useAuth";

const RejectedTravelersList = ({
  columnVisibilityModel,
  travelers,
  gridApi,
  setSelectedRow,
  getDataList,
  setTotalDataCount,
  rejected,
}: any) => {
  const navigate = useNavigate();

  const [openVehicleModal, setOpenVehicelModal] = useState(false);
  const handleVehicleModalClose = () => {
    setOpenVehicelModal(false);
  };

  const [idData, setIdData] = useState({
    idCard: "",
    residenceCard: "",
  });
  const [vehicleData, setVehicleData] = useState({
    vehicleReg: "",
    side: "",
    front: "",
    back: "",
    ticket: "",
    wayOfTravel: "",
  });
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const user = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [openPassportModal, setOpenPassportModal] = useState(false);
  const handleClosePassportModal = () => {
    setOpenPassportModal(false);
  };

  const [updateData] = useEditTravelerByIdMutation();
  const [deleteData] = useDeleteTravelerByIdMutation();

  const handleStatusChange = async (id: any, adminStatus: any) => {
    try {
      const body = {
        adminStatus: adminStatus,
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateData({
        id: `${id}`,
        body: encryptedData,
      }).unwrap();
      showToast("Traveller status updated successfully");
      getDataList();
      setAnchorEl1(null);
      setTotalDataCount((prevData: any) => {
        if (adminStatus == 1)
          return {
            ...prevData,
            type1: prevData.type1 + 1,
            type3: prevData.type3 - 1,
          };
        return prevData;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  const handleDelete = async (id: any) => {
    try {
      const body = {
        isDeleted: true,
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateData({
        id: `${id}`,
        body: encryptedData,
      }).unwrap();
      showToast("Traveller deleted successfully");
      getDataList();
      setAnchorEl1(null);
      setTotalDataCount((prevData: any) => {
        return { ...prevData, type3: prevData.type3 - 1 };
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };

  const getResidentTypeName = (residenceType: number) => {
    if (residenceType == 1) {
      return "CITIZEN";
    } else if (residenceType == 2) {
      return "RESIDENT";
    } else if (residenceType == 3) {
      return "VISITOR";
    } else {
      return "";
    }
  };
  const getTravelTypeName = (travelType: number) => {
    if (travelType == 1) {
      return "Private Vehicle";
    } else if (travelType == 2) {
      return "Airlines";
    } else if (travelType == 3) {
      return "Voyage";
    } else if (travelType == 4) {
      return "Railway";
    } else if (travelType == 5) {
      return "Public Road";
    } else {
      return "";
    }
  };
  const rows: any = [];
  travelers.map((data: any) => {
    rows.push({
      id: data?._id,
      TR: "TR" + data?.travelId,
      name: data?.userDetails?.[0]?.fullName
        ? data?.userDetails?.[0]?.fullName
        : "-",
      email: data?.userDetails?.[0]?.email
        ? data?.userDetails?.[0]?.email
        : "-",
      currentCity: data?.currentAddress?.city,
      mobile: data?.userDetails?.[0]?.countryCode
        ? data?.userDetails?.[0]?.countryCode + data?.userDetails?.[0]?.phone
        : "-",
      departureCityDate:
        data?.travelDetail?.[0]?.departingFrom +
        " /" +
        data?.travelDetail?.[0]?.departureDate?.slice(0, 10),
      arrivalCityDate:
        data?.travelDetail?.[data?.travelDetail?.length - 1]?.destination +
        "/" +
        data?.travelDetail?.[
          data?.travelDetail?.length - 1
        ]?.arrivalDate?.slice(0, 10),
      userId: data?.userDetails?.[0]?._id,
      image: data?.userDetails?.[0]?.personVerification?.profileImage,
      travelNo: data?.travelDetail?.length,
      residencyType: data?.personVerification?.residenceType
        ? getResidentTypeName(data?.personVerification?.residenceType)
        : "-",
      idCard: data?.personVerification?.idCard,
      residenceCard: data?.personVerification?.residenceCard,
      vehicleDetail: data?.vehicleDetail,
      ticket: data?.ticket,
      travelType: data?.travelType,
      wayOfTravel: getTravelTypeName(data?.travelType),
    });
  });

  const ActionCell = ({ data }: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false);
    };

    return (
      <div className="table_actions">
        <Box>
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img src="/static/images/more_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {user?.role == ADMIN_ROLE_ID ||
            user?.subadminRoleId?.permissions?.[0]?.isEdit ? (
              <MenuItem onClick={() => handleStatusChange(data?.row?.id, 1)}>
                Restore
              </MenuItem>
            ) : null}
            {user?.role == ADMIN_ROLE_ID ||
            user?.subadminRoleId?.permissions?.[0]?.isDelete ? (
              <MenuItem onClick={() => handleDelete(data?.row?.id)}>
                Delete Permanently
              </MenuItem>
            ) : null}
          </Menu>
        </Box>
      </div>
    );
  };
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 140,
      field: "TR",
      headerName: "TR",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 200,
      field: "name",
      headerName: "Name",
      editable: false,
      headerAlign: "center",
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + data?.row?.userId)
                  : null
              }
            >
              <img
                src={
                  data?.row?.image
                    ? data?.row?.image
                    : "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <Tooltip
              title={
                <p style={{ color: "white" }}>
                  {data?.row?.name}
                  <br />
                  {data?.row?.mobile}
                  <br />
                  {data?.row?.email}
                </p>
              }
            >
              <p className="cursor_p">
                <b>{data?.row?.name}</b>
                {data?.row?.mobile}
                <br />
                {data?.row?.email}
              </p>
            </Tooltip>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 110,
      field: "passportId",
      headerName: "Passport ID",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data: any) => {
        return (
          <IconButton
            className="text_icon"
            onClick={() => {
              setIdData({
                idCard: data?.row?.idCard,
                residenceCard: data?.row?.residenceCard,
              });
              if (data?.row?.residenceCard || data?.row?.idCard)
                setOpenPassportModal(true);
            }}
          >
            <img src="/static/images/external_icon.svg" alt="" />
          </IconButton>
        );
      },
    },
    {
      flex: 1,
      minWidth: 140,
      field: "residencyType",
      headerName: "Residency Type",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 140,
      field: "currentCity",
      headerName: "Current City",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 180,
      disableExport: true,
      field: "ticketVehicleReg",
      headerName: "Ticket/Vehicle Reg",
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data: any) => {
        return (
          <IconButton
            className="text_icon"
            onClick={() => {
              setVehicleData({
                ...data?.row?.vehicleDetail,
                ticket: data?.row?.ticket,
                wayOfTravel: data?.row?.wayOfTravel,
              });
              if (data?.row?.travelType) setOpenVehicelModal(true);
            }}
          >
            <img src="/static/images/external_icon.svg" alt="" />
          </IconButton>
        );
      },
    },
    {
      flex: 1,
      minWidth: 130,
      field: "travelNo",
      headerName: "# of travel",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 140,
      field: "email",
      headerName: "Email Address",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
  ];
  if (rejected) {
    columns?.push({
      flex: 1,
      minWidth: 80,
      disableExport: true,
      field: "action",
      headerName: "Action",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data) => <ActionCell data={data} />,
    });
  }
  // useEffect(()=>{
  //     if(rows)
  //     setSelectedRow((prevData:any)=>{
  //       return {...prevData,row:rows}
  //      })
  //   },[rows])
  let hidden = ["fullName"];

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          gridApi={gridApi}
          setSelectedRow={setSelectedRow}
          columnVisibilityModel={columnVisibilityModel}
          columns={columns?.filter((item) => !hidden.includes(item.field))}
          rows={rows}
        />
      </div>
      {/* <DatePicker value={dateValue} setValue={setDateValue} open={openModal} onClose={handleCloseModal} setOpen={setOpenModal} /> */}
      <VehicleRegistrationModal
        open={openVehicleModal}
        onClose={handleVehicleModalClose}
        setOpen={setOpenVehicelModal}
        vehicleData={vehicleData}
      />
      <PassportModal
        open={openPassportModal}
        onClose={handleClosePassportModal}
        setOpen={setOpenPassportModal}
        idData={idData}
      />
    </>
  );
};

export default RejectedTravelersList;
