import * as React from "react";
import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { pickersLayoutClasses } from "@mui/x-date-pickers/PickersLayout";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";

interface DatePickerModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  value?: any;
  setValue?: any;
  isLazy?: boolean;
  lazyValue?: any;
  setLazyValue?: any;
}

export default function DatePicker({
  open,
  onClose,
  setOpen,
  value,
  setValue,
  isLazy,
  lazyValue,
  setLazyValue,
}: DatePickerModalProps) {
  const [tempValue, setTempValue] = React.useState<any>([null, null]);
  React.useEffect(() => {
    console.log("UUUMMM", value);
    console.log("UUUMMM", lazyValue);

    if (value?.[0]) setTempValue(value);
    // else setTempValue(lazyValue);

    return () => setTempValue([null, null]);
  }, [open]);
  return (
    <Modal
      className="modal datepiker_modal"
      id="datepikerModal"
      aria-labelledby="datepiker-modal-title"
      aria-describedby="datepiker-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="modal_title">
            <h2>duration Date</h2>
            <p>Select Departure/Arrival duration dates</p>
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              value={tempValue}
              onChange={(newDate: any) => setTempValue(newDate)}
              defaultValue={[dayjs("2022-04-17"), dayjs("2022-04-21")]}
              sx={{
                [`.${pickersLayoutClasses.contentWrapper}`]: {
                  alignItems: "center",
                },
              }}
            />
          </LocalizationProvider>

          <div className="footer_btn_flex">
            <Button
              className="btn btn_white br"
              onClick={() => {
                if (isLazy) setLazyValue([null, null]);
                else setValue([null, null]);
                setOpen(false);
                setTempValue([null, null]);
              }}
            >
              Clear
            </Button>
            <Button
              className="btn btn_secondary"
              onClick={() => {
                setOpen(false);
                let temp: any = [tempValue[0], tempValue[1]];
                if (
                  (tempValue?.[0]?.["$d"] &&
                    new Date(tempValue?.[0]?.["$d"])?.toISOString()) !==
                    undefined &&
                  (tempValue?.[1]?.["$d"] &&
                    new Date(tempValue?.[1]?.["$d"])?.toISOString()) ==
                    undefined
                ) {
                  temp[1] = tempValue[0];
                  // setValue((prevData:any)=>{
                  //     return prevData.map((data:any,index:number)=>{
                  //         if(index==1)
                  //             {
                  //                 return tempValue[0];
                  //             }
                  //         return data;
                  //     })
                  // })
                }
                if (
                  (tempValue?.[0]?.["$d"] &&
                    new Date(tempValue?.[0]?.["$d"])?.toISOString()) ==
                    undefined &&
                  (tempValue?.[1]?.["$d"] &&
                    new Date(tempValue?.[1]?.["$d"])?.toISOString()) !==
                    undefined
                ) {
                  temp[0] = tempValue[1];
                  // setValue((prevData:any)=>{
                  //     return prevData.map((data:any,index:number)=>{
                  //         if(index==0)
                  //             {
                  //                 return tempValue[1];
                  //             }
                  //         return data;
                  //     })
                  // })
                }
                if (isLazy) setLazyValue(temp);
                else setValue(temp);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
