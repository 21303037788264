import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import GoogleRating from "../../features/dashboard/GoogleRating";
import SelectDuration from "../../features/dashboard/duraction";
import FeedbackReview from "../../features/dashboard/feedbackReview";
import TravelersQuestionnaire from "../../features/dashboard/travelersQuestionnaire";
import CustomersQuestionnaires from "../../features/dashboard/customersQuestionnaires";
import { showError } from "../../constants/toasts";
import { useLazyGetDashboardQuery } from "../../services/dashboard";
import { Dashboard as DashboardType } from "../../types/dashboard";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";
import { useLazyGetTravellerDetailChartQuery } from "../../services/reports";
import Loader from "../../constants/Loader";

const Dashboard = () => {
  const navigate = useNavigate();

  const formatNumber = (value: number) => {
    if (value > 999999999999999) {
      return "NAN";
    } else if (value >= 1000000000000) {
      return (
        (value / 1000000000000).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) + "T"
      );
    } else if (value >= 1000000000) {
      //   value=value.toFixed(0);
      return (
        (value / 1000000000).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) + "B"
      );
    } else if (value >= 1000000) {
      //   value=value.toFixed(0);
      return (
        (value / 1000000).toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) + "M"
      );
    } else if (value >= 100000) {
      return (
        (value / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 }) +
        "K"
      );
    } else {
      return value.toLocaleString();
    }
  };
  const [getDashboard, { isLoading }] = useLazyGetDashboardQuery();
  const [getReport, { isLoading: isLoading2 }] =
    useLazyGetTravellerDetailChartQuery();

  const [dashboardData, setDashboardData] = React.useState<DashboardType>({
    incompleteUserCount: 0,
    invitedUsers: 0,
    message: "",
    newUsers: 0,
    userCount: 0,
  });
  const [reportData, setReportData] = useState<any>({});
  const getData = async () => {
    try {
      const response = await getDashboard({}).unwrap();
      if (response.statusCode == 200) {
        console.log("DASHBOARD API WORKING");
        setDashboardData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getReportData = async () => {
    try {
      const response2 = await getReport({}).unwrap();
      if (response2.statusCode == 200) {
        console.log("DASHBOARD API WORKING");
        setReportData({
          total: response2?.data?.travelTypeCounts?.totalCount,
          travelTypeCounts: response2?.data?.travelTypeCounts,
          shipmentGenderData: response2?.data?.shipmentCustGenders?.[0],
          travelGenderData: response2?.data?.travellerGenders?.[0],
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getData();
    getReportData();
  }, []);
  const user = useAuth();
  return (
    <>
      <Loader isLoad={isLoading || isLoading2} />
      <div className="main_title">
        <h1>Dashboard</h1>
        <p>Welcome to ginaEx</p>
      </div>
      <div className="stats_boxes gap_m">
        <div
          className="cards"
          style={{ width: "18%" }}
          // onClick={() => (
          //   ((user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isView)?
          //   navigate("/dashboard/downloaded-users") : null))}
        >
          <figure>
            <img src="/static/images/download_icon.svg" alt="" />
          </figure>
          <h2>{0}</h2>
          <p># of all users who downloaded application</p>
        </div>
        <div
          className="cards"
          style={{ width: "18%" }}
          // onClick={() => (
          //   ((user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isView)?
          //   navigate("/dashboard/uninstalled-users") : null))}
        >
          <figure>
            <img src="/static/images/download_icon.svg" alt="" />
          </figure>
          <h2>0</h2>
          <p># of users who uninstalled application</p>
        </div>
        <div
          className="cards"
          style={{ width: "18%" }}
          onClick={() =>
            user?.role == ADMIN_ROLE_ID ||
            user?.subadminRoleId?.permissions?.[6]?.isView
              ? navigate("/dashboard/registered-users")
              : null
          }
        >
          <figure>
            <img src="/static/images/download_icon.svg" alt="" />
          </figure>
          <h2>{formatNumber(dashboardData?.userCount)}</h2>
          <p>Total number of registered users</p>
        </div>
        <div
          className="cards"
          style={{ width: "18%" }}
          onClick={() =>
            user?.role == ADMIN_ROLE_ID ||
            user?.subadminRoleId?.permissions?.[6]?.isView
              ? navigate("/dashboard/unregistered-users")
              : null
          }
        >
          <figure>
            <img src="/static/images/download_icon.svg" alt="" />
          </figure>
          <h2>{formatNumber(dashboardData?.incompleteUserCount)}</h2>
          <p># of Incompletely registered users</p>
        </div>
        <div
          className="cards"
          style={{ width: "18%" }}
          // onClick={() => (
          // ((user?.role==ADMIN_ROLE_ID||user?.subadminRoleId?.permissions?.[6]?.isView)?
          // navigate("/dashboard/invited-users") : null))}
        >
          <figure>
            <img src="/static/images/download_icon.svg" alt="" />
          </figure>
          <h2>{formatNumber(dashboardData?.invitedUsers)}</h2>
          <p>Total number of Invited users </p>
        </div>
      </div>
      <div className="grid_sc gap_m">
        <GoogleRating />
        <SelectDuration data={reportData} />
        <FeedbackReview />
        <TravelersQuestionnaire />
        {/* {<CustomersQuestionnaires />} */}
      </div>
    </>
  );
};

export default Dashboard;
