/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const TravelersQuestionnaire = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="cards table_card">
        <div className="cards_header">
          <div className="left_s">
            <h2>Travelers Questionnaire (Internal)</h2>
            <p>More than 400 feedbacks</p>
          </div>
          <div className="right_s">
            {/* <p>
              <span>Month</span>
              <span>Week</span>
              <span>Today</span>
            </p> */}
            <Button className="btn btn_highlight">
              <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
            </Button>
          </div>
        </div>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Membership #</TableCell>
                <TableCell>travel purpose</TableCell>
                <TableCell>feedback</TableCell>
                <TableCell>General RATING</TableCell>
                <TableCell>Last Visit</TableCell>
                <TableCell>Recommend Friends</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <div className="user_block">
                    <figure>
                      <img src="/static/images/user_attachment.jpg" alt="" />
                    </figure>
                    <p>
                      <b>Emma Somith</b> GN12xxxxxxx
                    </p>
                  </div>
                </TableCell>
                <TableCell>Visit Family & Friends</TableCell>
                <TableCell className="max_c">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </TableCell>
                <TableCell>
                  <div className="star_rating">
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon />
                  </div>
                </TableCell>
                <TableCell>1 year</TableCell>
                <TableCell>
                  <div className="star_rating">
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className="user_block">
                    <figure onClick={() => navigate("/profile-detail")}>
                      <img src="/static/images/user_attachment.jpg" alt="" />
                    </figure>
                    <p>
                      <b>Emma Somith</b> GN12xxxxxxx
                    </p>
                  </div>
                </TableCell>
                <TableCell>Visit Family & Friends</TableCell>
                <TableCell className="max_c">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </TableCell>
                <TableCell>
                  <div className="star_rating">
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon />
                  </div>
                </TableCell>
                <TableCell>1 year</TableCell>
                <TableCell>
                  <div className="star_rating">
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon />
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <div className="user_block">
                    <figure onClick={() => navigate("/profile-detail")}>
                      <img src="/static/images/user_attachment.jpg" alt="" />
                    </figure>
                    <p>
                      <b>Emma Somith</b> GN12xxxxxxx
                    </p>
                  </div>
                </TableCell>
                <TableCell>Visit Family & Friends</TableCell>
                <TableCell className="max_c">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </TableCell>
                <TableCell>
                  <div className="star_rating">
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon />
                  </div>
                </TableCell>
                <TableCell>1 year</TableCell>
                <TableCell>
                  <div className="star_rating">
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon className="active" />
                    <StarIcon />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="text_center">
          <a href="javascript:void(0)">Show More</a>
        </div>
      </div>
    </>
  );
};

export default TravelersQuestionnaire;
