import * as React from "react";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart";

export default function PieChartGraph({ data, total }: any) {
  const formattedValue = (item: { value: number }) => {
    return `${((item?.value / total) * 100).toFixed(2)}%`;
  };
  return (
    <PieChart
      series={[
        {
          //   arcLabel: "formattedValue",
          //   arcLabelMinAngle: 50,
          innerRadius: 50,
          data,
          valueFormatter: formattedValue,
        },
      ]}
      width={300}
      height={160}
    />
  );
}
