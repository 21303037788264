import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface ViewRemarkModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
}

export default function ViewRemark({
  open,
  onClose,
  setOpen,
  data,
}: ViewRemarkModalProps) {
  return (
    <Modal
      className="modal remark_modal"
      id="ViewRemarkModal"
      aria-labelledby="ViewRemark-modal-title"
      aria-describedby="ViewRemark-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Remark</h2>
          </div>
          <div className="remark_detail">
            <div className="user_block">
              <figure>
                <img
                  src={data?.image || "/static/images/user-placeholder.jpg"}
                  alt=""
                />
              </figure>
              <p>
                {data?.name || "Admin User"}
                <br />
                <span style={{ fontSize: "12px" }}>
                  {data?.email || "Admin Email"}
                </span>
              </p>
            </div>
            <p>{data?.remark ? data?.remark : "No Remarks"}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
