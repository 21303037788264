import ProfileDetail from "./ProfileDetail";
import ForgotPassword from "./auth/ForgotPassword";
import Login from "./auth/Login";
import ResetPassword from "./auth/ResetPassword";
import BeneficiaryCustomers from "./beneficiary";
import BroadcastMessage from "./broadcastMessage";
import Chat from "./chat";
import Dashboard from "./dashboard";
import InvitedUsers from "./downloadedUsers";
import DownloadedUsers from "./downloadedUsers";
import EditProfile from "./editProfile";
import FreightCustomers from "./freightCustomers";
import LookingForTravellers from "./lookingForTravellers";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotifications";
import MembershipLevelBronze from "./membershipLevel";
import RecievedNotifications from "./recievedNotifications";
import RegisteredUsers from "./registeredUsers";
import ReportAnIssue from "./reportAnIssue";
import Reports from "./reports";
import ShipmentFeedback from "./shipmentTracks/ShipmentFeedback";
import DeliverToTravelers from "./shipmentTracks/deliverToTravelers";
import NewOrders from "./shipmentTracks/newOrders";
import PassengerFlight from "./shipmentTracks/passengerFlight";
import TravelerList from "./tranvelerList";
import Travelers from "./travelers";
import UninstalledUsers from "./uninstalledUsers";
import UnregisteredUsers from "./unregisteredUsers";
import Roles from "./userManagement/role";
import UserList from "./userManagement/userList";

export default {
  Login,
  Dashboard,
  ForgotPassword,
  ResetPassword,
  DownloadedUsers,
  TravelerList,
  UninstalledUsers,
  RegisteredUsers,
  UnregisteredUsers,
  Travelers,
  FreightCustomers,
  BeneficiaryCustomers,
  NewOrders,
  DeliverToTravelers,
  PassengerFlight,
  ShipmentFeedback,
  ReportAnIssue,
  LookingForTravellers,
  MembershipLevelBronze,
  Reports,
  Roles,
  UserList,
  BroadcastMessage,
  Chat,
  ProfileDetail,
  EditProfile,
  ManageNotifications,
  RecievedNotifications,
  ManageCms,
  InvitedUsers,
};
