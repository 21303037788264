/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SearchBar from "../../../components/SearchBar";
import {
  Button,
  Box,
  Menu,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { exportToPdf } from "../../../utils/exportToPdf";
import { exportToExcel } from "../../../utils/exportToExcel";
import { showError } from "../../../constants/toasts";

const PassengerFlightFilter = ({
  setColumnVisibilityModel,
  columnVisibilityModel,
  setSearchTerm,
  searchTerm,
  gridApi,
  selectedRow,
}: any) => {
  const [searchValue, setSearchValue] = useState("");
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  return (
    <>
      <Box className="search_box c_white">
        <SearchBar
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setSearchTerm}
          placeholder="Search travelers"
          value={searchTerm}
          onCross={() => {
            setSearchTerm("");
            setSearchValue("");
          }}
          onChange={(e: any) => {
            setSearchTerm(e.target.value);
            setSearchValue(e.target.value);
          }}
        />
      </Box>
      <Box>
        <Button
          className="btn btn_white"
          aria-controls={open1 ? "basic-menu1" : undefined}
          aria-haspopup="true"
          aria-expanded={open1 ? "true" : undefined}
          onClick={handleClick1}
        >
          <img src="/static/images/eye_icon.svg" alt="Icon" /> Display Fields
        </Button>
        <Menu
          className="filter_menu"
          id="basic-menu1"
          anchorEl={anchorEl1}
          open={open1}
          onClose={handleClose1}
          MenuListProps={{
            "aria-labelledby": "basic-button1",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
              borderRadius: "10px",
              mt: 1,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <h2>Display & Sort Fields</h2>
            <p>Displaying 10 fields in a row</p>
          </MenuItem>
          <MenuItem className="checkbox_group">
            <FormGroup className="gap_m">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.traveler}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        traveler: !prevData.traveler,
                      }))
                    }
                  />
                }
                label="Traveler"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.travelerMobile}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        travelerMobile: !prevData.travelerMobile,
                      }))
                    }
                  />
                }
                label="Contact"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.travelerEmail}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        travelerEmail: !prevData.travelerEmail,
                      }))
                    }
                  />
                }
                label="Email"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.currentCity}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        currentCity: !prevData.currentCity,
                      }))
                    }
                  />
                }
                label="Current City"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.status}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        status: !prevData.status,
                      }))
                    }
                  />
                }
                label="Status"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.shipments}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        shipments: !prevData.shipments,
                      }))
                    }
                  />
                }
                label="Shipment"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.departureDate}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        departureDate: !prevData.departureDate,
                      }))
                    }
                  />
                }
                label="Departure Date"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.delayDepartureDate}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        delayDepartureDate: !prevData.delayDepartureDate,
                      }))
                    }
                  />
                }
                label="Delay Departure Date"
              /> */}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.destinationCity}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        destinationCity: !prevData.destinationCity,
                      }))
                    }
                  />
                }
                label="Destination City (1)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.destinationCity2}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        destinationCity2: !prevData.destinationCity2,
                      }))
                    }
                  />
                }
                label="Destination City (2)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.destinationCity3}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        destinationCity3: !prevData.destinationCity3,
                      }))
                    }
                  />
                }
                label="Destination City (3)"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.action}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        action: !prevData.action,
                      }))
                    }
                  />
                }
                label="Action"
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.dateOfAcceptance}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        dateOfAcceptance: !prevData.dateOfAcceptance,
                      }))
                    }
                  />
                }
                label="Date of Acceptance"
              />
              {columnVisibilityModel.hasOwnProperty('flag') && <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.flag}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        flag: !prevData.flag,
                      }))
                    }
                  />
                }
                label="Flag"
              />}
              {columnVisibilityModel.hasOwnProperty('comment') && <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.comment}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        comment: !prevData.comment,
                      }))
                    }
                  />
                }
                label="Comment"
              />}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.availableWeight}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        availableWeight: !prevData.availableWeight,
                      }))
                    }
                  />
                }
                label="Available Weight"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={columnVisibilityModel?.wayOfTravel}
                    onChange={() =>
                      setColumnVisibilityModel((prevData: any) => ({
                        ...prevData,
                        wayOfTravel: !prevData.wayOfTravel,
                      }))
                    }
                  />
                }
                label="Way of Travel"
              />
            </FormGroup>
          </MenuItem>
        </Menu>
      </Box>
      <Box>
        <Button
          className="btn btn_secondary"
          aria-controls={open3 ? "basic-menu3" : undefined}
          aria-haspopup="true"
          aria-expanded={open3 ? "true" : undefined}
          onClick={handleClick3}
        >
          <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
        </Button>
        <Menu
          className="filter_menu"
          id="basic-menu3"
          anchorEl={anchorEl3}
          open={open3}
          onClose={handleClose3}
          MenuListProps={{
            "aria-labelledby": "basic-button3",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
              borderRadius: "10px",
              mt: 1,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <h2>Choose Option</h2>
            <p>Select an option to export</p>
          </MenuItem>
          <MenuItem className="export_btns">
            <Button
              onClick={() => {
                selectedRow?.row?.length
                  ? exportToPdf(
                    selectedRow?.row,
                    columnVisibilityModel?.hasOwnProperty('flag') ? selectedRow?.column : selectedRow?.column?.slice(0, -1)
                  )
                  : showError("Please select atleast one row");
              }}
            >
              PDF
            </Button>
            <Button
              onClick={() => {
                selectedRow?.row?.length
                  ? gridApi.current.exportDataAsCsv()
                  : showError("Please select atleast one row");
              }}
            >
              CSV
            </Button>
            <Button
              onClick={() => {
                selectedRow?.row?.length
                  ? exportToExcel(selectedRow?.row, selectedRow?.column)
                  : showError("Please select atleast one row");
              }}
            >
              Excel
            </Button>
            {/* <Button>Txt</Button> */}
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default PassengerFlightFilter;
