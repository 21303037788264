/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import TableComponent from "../../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import EditDepartureDate from "../../../Modals/editDepartureDate";
import ShipmentSummaryModal from "../../../Modals/shipmentSummary";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAuth from "../../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../../constants/role";
import { NewOrder, TravelDetail } from "../../../types/newOrder";
import { ORDER_TRACKING_STATUS } from "../../../constants/shipmentOrder";
import increaseDays from "../../../utils/increaseDays";
import { OrderDetailPassengerFlight } from "../../../types/passengerFlight";
import { getLuggageName } from "../../../constants/cargoType";
import { getTravelTypeName } from "../../../constants/wayOfTravelImages";
import { InfoTwoTone } from "@mui/icons-material";
import AddComment from "../../../Modals/addComment";
import {
  useEditNewOrderByIdMutation,
  useEditPassengerFlightByIdMutation,
} from "../../../services/shipmentTrack";
import { showError, showToast } from "../../../constants/toasts";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import dayjs from "dayjs";

const DelayedFlightsList = ({
  columnVisibilityModel,
  newOrders,
  setSelectedRow,
  gridApi,
  getDataList,
}: {
  columnVisibilityModel: any;
  newOrders: NewOrder[];
  setSelectedRow: any;
  gridApi: any;
  getDataList: any;
}) => {
  const navigate = useNavigate();
  const [editById] = useEditPassengerFlightByIdMutation();
  const editByIdData = async (id: string, body: any) => {
    try {
      const encryptedBody = generateEncryptedKeyBody(body);
      const response = await editById({ id, body: encryptedBody }).unwrap();
      if (response?.statusCode == 200) {
        showToast("Passenger flight Updated successfully");
        setComment([]);
        setOpenModal3(false);
        setOpenModal(false);
        setTimeout(() => {
          getDataList();
        }, 100);
      }
    } catch (error: any) {
      showError(error?.data?.message || "Something went wrong");
    }
  };
  const [comment, setComment] = useState<any[]>([]);
  const [departureDate, setDepartureDate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [openModal3, setOpenModal3] = useState(false);
  const handleCloseModal3 = () => {
    setOpenModal3(false);
  };
  const [shipmentDetails, setShipmentDetails] = useState<NewOrder | any>();
  const [selectedId, setSelectedId] = useState("");
  const [openModal2, setOpenModal2] = useState(false);
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const user = useAuth();
  const rows: any = [];
  const [updateData] = useEditNewOrderByIdMutation();
  const handleStatusChange = async (
    id: any,
    adminStatus: any,
    index: number | null,
    hideToast?: boolean,
    flag?: boolean
  ) => {
    try {
      const body: any = {};
      if (adminStatus) {
        body["delayOrderStatus"] = adminStatus;
      } else {
        body["flag"] = flag;
      }
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateData({
        id: `${id}`,
        body: encryptedData,
      }).unwrap();
      if (!hideToast)
        if (adminStatus)
          showToast("Shipment order status updated successfully");
        else {
          showToast("Flag updated successfully");
        }
      if (adminStatus)
        setShipmentDetails((prev: any) => ({
          ...prev,
          orders: prev?.orders?.map((data: any, index2: number) =>
            index2 == index ? { ...data, delayOrderStatus: adminStatus } : data
          ),
        }));
      else
        setShipmentDetails((prev: any) => ({
          ...prev,
          orders: prev?.orders?.map((data: any, index2: number) =>
            index2 == index ? { ...data, flag: flag } : data
          ),
        }));
      getDataList();
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  newOrders?.map((data: any) => {
    rows.push({
      data: data,
      id: data?._id,
      traveler: data?.traveller?.fullName,
      travelerImage: data?.traveller?.personVerification?.profileImage,
      travelerId: data?.traveller._id,
      travelerEmail: data?.traveller?.email || "",
      travelerName:
        data?.traveller?.firstName + " " + data?.traveller?.lastName,
      travelerMobile: data?.traveller?.countryCode + data?.traveller?.phone,
      freightCustomer:
        data?.customer?.firstName + " " + data?.customer?.lastName,
      freightCustomerImage: data?.customer?.personVerification?.profileImage,
      freightCustomerId: data?.customer?._id,
      freightCustomerMobile:
        data?.customer?.countryCode + data?.customer?.phone,
      shipments: data?.orders?.length ? `${data?.orders?.length} orders` : "-",
      comment: data?.comment?.length
        ? `${data?.comment?.length} comments`
        : "No comments",
      shipmentNo: data?.shipmentId?.shipmentId
        ? data?.shipmentId?.shipmentId
        : "-",
      currentCity: data?.travelDetail?.[0]?.departingFrom
        ? data?.travelDetail?.[0]?.departingFrom
        : "-",
      destinationCity: data?.travelDetail?.[0]?.destination
        ? data?.travelDetail?.[0]?.destination
        : "-",
      destinationCity2: data?.travelDetail?.[1]?.destination
        ? data?.travelDetail?.[1]?.destination
        : "-",
      destinationCity3: data?.travelDetail?.[2]?.destination
        ? data?.travelDetail?.[2]?.destination
        : "-",
      dateOfOrder: data?.createdAt?.slice(0, 10),
      expectingDeliveryDate: increaseDays(data?.createdAt, 2)?.slice(0, 10),
      dateOfAcceptance: data?.orders?.length
        ? data?.orders?.length !== 1
          ? `${data?.orders?.length} Acceptance dates`
          : data?.orders?.[0]?.orderAcceptedAt?.slice(0, 10)
        : "-",
      delayDepartureDate:
        data?.orders?.length !== 1
          ? `${data?.orders?.length} Delay Departure dates`
          : data?.orders?.[0]?.delayDepartureDate
          ? data?.orders?.[0]?.delayDepartureDate?.slice(0, 10)
          : "-",
      // dateOfAcceptance: data?.acceptanceDate
      //   ? data?.acceptanceDate?.slice(0, 10)
      //   : "-",
      orders: data?.orders,
      departureDate: data?.travelDetail?.[0]?.departureDate
        ? data?.travelDetail?.[0]?.departureDate?.slice(0, 10)
        : "-",
      status: ORDER_TRACKING_STATUS[data?.trackingStatus],
      statusEnum: data?.trackingStatus,
      travelDetail: data?.travelDetail,
      commentData: data?.comment,
      flag: Boolean(data.orders?.some((data: any) => data?.flag))
        ? "Yes"
        : "No",
      wayOfTravel:
        data?.travelDetail?.length !== 1
          ? `${data?.travelDetail?.length} Travels`
          : getTravelTypeName(data?.travelDetail?.[0]?.travelType),
      availableWeight:
        data?.travelDetail?.length !== 1
          ? `${data?.travelDetail?.length} Weights`
          : data?.travelDetail?.[0]?.availableWeight,
    });
  });

  console.log(rows, "kkkk");
  const ActionCell = ({ data }: any) => {
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchorEl1(null);
    };
    return (
      <div className="table_actions">
        <Box>
          <IconButton
            aria-controls={open1 ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick1}
          >
            <img src="/static/images/more_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu"
            id="basic-menu1"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                for (let i = 0; i < data?.length; i++) {
                  handleStatusChange(
                    data?.[i]?._id,
                    1,
                    null,
                    data?.length - 1 == i
                  );
                }
              }}
            >
              Not Accepted
            </MenuItem>
            <MenuItem
              onClick={() => {
                for (let i = 0; i < data?.length; i++) {
                  handleStatusChange(
                    data?.[i]?._id,
                    2,
                    null,
                    data?.length - 1 == i
                  );
                }
              }}
            >
              Accepted
            </MenuItem>
            <MenuItem
              onClick={() => {
                for (let i = 0; i < data?.length; i++) {
                  handleStatusChange(
                    data?.[i]?._id,
                    3,
                    null,
                    data?.length - 1 == i
                  );
                }
              }}
            >
              Returned
            </MenuItem>
          </Menu>
        </Box>
      </div>
    );
  };
  const ActionCell2 = ({ id, index }: any) => {
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchorEl1(null);
    };
    return (
      <div className="table_actions">
        <Box>
          <IconButton
            aria-controls={open1 ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick1}
          >
            <img src="/static/images/more_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu"
            id="basic-menu1"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => handleStatusChange(id, 1, index)}>
              Not Accepted
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange(id, 2, index)}>
              Accepted
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange(id, 3, index)}>
              Returned
            </MenuItem>
          </Menu>
        </Box>
      </div>
    );
  };
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 200,
      field: "traveler",
      headerName: "Traveler",
      editable: false,
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + data?.row?.travelerId)
                  : null
              }
            >
              <img
                src={
                  data?.row?.travelerImage
                    ? data?.row?.travelerImage
                    : "/static/images/user_placeholder.png"
                }
                alt=""
              />
            </figure>
            <p>
              <b>{data?.row?.travelerName}</b> {data?.row?.travelerEmail}
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 130,
      field: "travelerMobile",
      headerName: "Contact",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 130,
      field: "travelerEmail",
      headerName: "Email",
      editable: false,
      sortable: false,
    },
    // {
    //   flex: 1,
    //   minWidth: 130,
    //   field: "delayDepartureDate",
    //   headerName: "Delay Departure Date",
    //   editable: false,
    //   sortable: false,
    //   renderCell: (data) => {

    //     const luggageArray: any = [];
    //     data?.row?.orders?.map((data2: any) => {
    //       if (data2?.delayDepartureDate)
    //         luggageArray?.push(data2?.delayDepartureDate);
    //     })
    //     console.log('luggageArray :', luggageArray);
    //     const displayData = <ul>{data?.row?.orders?.map((data2: any, index: number) => {
    //       return <li>{(index + 1) + ". " + (data2?.delayDepartureDate ? `${data2?.delayDepartureDate} ` : "-")}</li>
    //     })}</ul>
    //     return (
    //       <>
    //         {
    //           luggageArray.length ? (luggageArray?.length == 1 ? <p>{luggageArray[0]?.slice(0, 10)}</p> : <p style={{ display: "flex", alignItems: "center" }}>{luggageArray?.length + " delay departure dates  "}
    //             <Tooltip title={displayData} arrow>
    //               <IconButton>
    //                 <InfoTwoTone />
    //               </IconButton>
    //             </Tooltip>
    //           </p>) : "-"
    //         }
    //       </>
    //     )
    //   }
    // },
    {
      flex: 1,
      minWidth: 220,
      align: "center",
      field: "dateOfAcceptance",
      headerName: "Date of Acceptance",
      editable: false,
      sortable: false,
      renderCell: (data) => {
        const luggageArray: any = [];
        data?.row?.orders?.map((data2: any) => {
          if (data2?.orderAcceptedAt)
            luggageArray?.push(data2?.orderAcceptedAt);
        });
        console.log("luggageArray :", luggageArray);
        const displayData = (
          <ul>
            {data?.row?.orders?.map((data2: any, index: number) => {
              return (
                <li>
                  {index +
                    1 +
                    ". " +
                    (data2?.orderAcceptedAt
                      ? `${data2?.orderAcceptedAt?.slice(0, 10)} `
                      : "-")}
                </li>
              );
            })}
          </ul>
        );
        return (
          <>
            {luggageArray.length ? (
              luggageArray?.length == 1 ? (
                <p>{luggageArray[0]?.slice(0, 10)}</p>
              ) : (
                <p style={{ display: "flex", alignItems: "center" }}>
                  {luggageArray?.length + " order accepted dates  "}
                  <Tooltip title={displayData} arrow>
                    <IconButton>
                      <InfoTwoTone />
                    </IconButton>
                  </Tooltip>
                </p>
              )
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      flex: 1,
      minWidth: 160,
      field: "currentCity",
      headerName: "Current city",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 200,
      field: "destinationCity",
      headerName: "Destination City (1)",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 200,
      field: "destinationCity2",
      headerName: "Destination City (2)",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 200,
      field: "destinationCity3",
      headerName: "Destination City (3)",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 180,
      field: "wayOfTravel",
      headerName: "Way Of Travel",
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data) => {
        const luggageArray: any = [];
        data?.row?.travelDetail?.map((data2: TravelDetail | any) => {
          if (data2?.travelType) luggageArray?.push(data2?.travelType);
        });
        console.log("luggageArray :", luggageArray);
        const displayData = (
          <ul>
            {data?.row?.travelDetail?.map(
              (data2: TravelDetail | any, index: number) => {
                return (
                  <li>
                    {index +
                      1 +
                      ". " +
                      (getTravelTypeName(data2?.travelType)?.trim().length
                        ? getTravelTypeName(data2?.travelType)
                        : "-")}
                  </li>
                );
              }
            )}
          </ul>
        );
        return (
          <>
            {luggageArray.length ? (
              luggageArray?.length == 1 ? (
                <p>{getTravelTypeName(luggageArray[0])}</p>
              ) : (
                <p style={{ display: "flex", alignItems: "center" }}>
                  {luggageArray?.length + " Travels  "}
                  <Tooltip title={displayData} arrow>
                    <IconButton>
                      <InfoTwoTone />
                    </IconButton>
                  </Tooltip>
                </p>
              )
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      flex: 1,
      minWidth: 180,
      field: "availableWeight",
      headerName: "Available Weight",
      editable: false,
      renderCell: (data) => {
        const luggageArray: any = [];
        data?.row?.travelDetail?.map((data2: TravelDetail | any) => {
          if (data2?.availableWeight)
            luggageArray?.push(data2?.availableWeight);
        });
        console.log("luggageArray :", luggageArray);
        const displayData = (
          <ul>
            {data?.row?.travelDetail?.map(
              (data2: TravelDetail | any, index: number) => {
                return (
                  <li>
                    {index +
                      1 +
                      ". " +
                      (data2?.availableWeight
                        ? `${data2?.availableWeight} KG`
                        : "-")}
                  </li>
                );
              }
            )}
          </ul>
        );
        return (
          <>
            {luggageArray.length ? (
              luggageArray?.length == 1 ? (
                <p>{luggageArray[0]}</p>
              ) : (
                <p style={{ display: "flex", alignItems: "center" }}>
                  {luggageArray?.length + " available weights  "}
                  <Tooltip title={displayData} arrow>
                    <IconButton>
                      <InfoTwoTone />
                    </IconButton>
                  </Tooltip>
                </p>
              )
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "flag",
      headerName: "Flag",
      editable: false,
      renderCell: ({ row }) => {
        const isFlag = row?.orders?.some((data: any) => data?.flag);
        return (
          <IconButton disabled={true}>
            <img
              src={
                isFlag
                  ? "/static/images/flag-check.svg"
                  : "/static/images/flag_icon.svg"
              }
              alt="Icon"
            />
          </IconButton>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "comment",
      headerName: "Comment",
      editable: false,
      renderCell: ({ row }) => {
        return (
          <IconButton className="text_icon">
            <img
              src="/static/images/comment_icon.svg"
              alt="Icon"
              onClick={() => {
                setComment(row?.commentData || []);
                setSelectedId(row?.id);
                setTimeout(() => {
                  setOpenModal3(true);
                }, 100);
              }}
            />
          </IconButton>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "shipments",
      headerName: "Shipments",
      editable: false,
      sortable: false,
      renderCell: (data) => {
        return (
          <p
            className="view_link"
            onClick={() => {
              setShipmentDetails(data?.row?.data);
              setOpenModal2(true);
            }}
          >
            View detail
          </p>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "departureDate",
      headerName: "Departure Date",
      editable: false,
      renderCell: (data) => {
        return (
          <p className="departure_date">
            {data?.row?.departureDate}

            <IconButton>
              <img
                src="/static/images/edit2_icon.svg"
                alt="Icon"
                onClick={() => {
                  setSelectedId(data?.row?.id);
                  setDepartureDate(data?.row?.departureDate);
                  setComment(data?.row?.travelDetail);
                  setTimeout(() => {
                    setOpenModal(true);
                  }, 100);
                }}
              />
            </IconButton>
          </p>
        );
      },
    },
    // {
    //   flex: 1,
    //   minWidth: 120,
    //   field: "status",
    //   headerName: "Status",
    //   editable: false,
    //   renderCell: ({ row }) => {
    //     return (
    //       (row?.data?.orders?.every((data: any) => data.delayOrderStatus == 1)
    //         ?
    //         <Button className="btn_text c_danger">Not Accepted</Button>
    //         :
    //         ((row?.data?.orders?.every((data: any) => data.delayOrderStatus == 2)
    //           ?
    //           <Button className="btn_text c_success">Accepted</Button>
    //           :
    //           ((row?.data?.orders?.every((data: any) => data.delayOrderStatus == 3)
    //             ?
    //             <Button className="btn_text c_info">Returned</Button>
    //             :
    //             <Button className="btn_text c_pink">Confirmed</Button>)))))

    //     )
    //   },
    // },
    // {
    //   flex: 1,
    //   minWidth: 80,
    //   field: "action",
    //   headerName: "Action",
    //   editable: false,
    //   sortable: false,
    //   renderCell: ({ row }: any) => {
    //     return (
    //       <ActionCell data={row?.data?.orders} />
    //     );
    //   },
    // },
  ];
  let hidden = ["fullName"];

  const accordionContent = (
    <>
      {Array.isArray(shipmentDetails?.orders) &&
        shipmentDetails?.orders?.map(
          (data: OrderDetailPassengerFlight, index: number) => {
            return (
              <Accordion
                expanded={expanded === index.toString()}
                onChange={handleChange(index.toString())}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  Shipping NO {data.orderId || "XXX XXX 000100"}
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table className="v2" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Shipment customers</TableCell>
                          <TableCell>Contact</TableCell>
                          <TableCell>Luggage type</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Total Weight (Kgs)</TableCell>
                          <TableCell>Offer</TableCell>
                          <TableCell>Flag</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{index + 1 || "1"}</TableCell>
                          <TableCell>
                            <div className="user_block">
                              <figure
                                onClick={() =>
                                  navigate(
                                    "/profile-detail/" + data?.customer?._id
                                  )
                                }
                              >
                                <img
                                  src={
                                    data?.customer?.personVerification
                                      ?.profileImage ||
                                    "/static/images/user_attachment.jpg"
                                  }
                                  alt=""
                                />
                              </figure>
                              <p>
                                <b>
                                  {data?.customer?.fullName || "Emma smith"}
                                </b>{" "}
                                {data?.customer?.email || "samith@gmail.com"}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell>
                            {data?.customer?.countryCode +
                              data?.customer?.phone || "-"}
                          </TableCell>
                          <TableCell className="double_value">
                            {data?.packageDetails?.[0]?.cargoType
                              ? data?.packageDetails?.map((data2) => (
                                  <span>{getLuggageName(data2.cargoType)}</span>
                                ))
                              : "-"}
                            {/* <span>Box</span> */}
                          </TableCell>
                          <TableCell className="double_value">
                            {data?.packageDetails?.[0]?.quantity
                              ? data?.packageDetails?.map((data2) => (
                                  <span>{data2.quantity}</span>
                                ))
                              : "-"}
                            {/* <span>2</span> */}
                          </TableCell>
                          <TableCell className="double_value">
                            {data?.packageDetails?.[0]?.totalWeight
                              ? data?.packageDetails?.map((data2) => (
                                  <span>{`${data2.totalWeight} KG`}</span>
                                ))
                              : "-"}
                            {/* <span>10KG</span> */}
                          </TableCell>
                          <TableCell className="double_value">
                            {data?.packageDetails?.[0]?.totalWeight
                              ? data?.packageDetails?.map((data2) => (
                                  <span>{`$${data2.amount}`}</span>
                                ))
                              : "-"}
                            {/* <span>$90</span> */}
                          </TableCell>
                          <TableCell className="double_value">
                            <IconButton
                              className="text_icon"
                              onClick={() => {
                                // if (row?.hasOwnProperty('flag')) {
                                handleStatusChange(
                                  data?._id,
                                  false,
                                  index,
                                  false,
                                  !data?.flag
                                );
                                // }
                              }}
                            >
                              <img
                                src={
                                  data?.flag
                                    ? "/static/images/flag-check.svg"
                                    : "/static/images/flag_icon.svg"
                                }
                                alt="Icon"
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            {data?.delayOrderStatus != 2 &&
                              data?.delayOrderStatus != 3 && (
                                <Button className="btn_text c_info">
                                  Not Accepted
                                </Button>
                              )}
                            {/* {data?.delayOrderStatus == 1 && <Button className="btn_text c_info">Not Accepted</Button>} */}
                            {data?.delayOrderStatus == 2 && (
                              <Button className="btn_text c_success">
                                Accepted
                              </Button>
                            )}
                            {data?.delayOrderStatus == 3 && (
                              <Button className="btn_text c_danger">
                                Returned
                              </Button>
                            )}
                          </TableCell>
                          <TableCell>
                            <ActionCell2 id={data?._id} index={index} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            );
          }
        )}
      {/* <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          Shipping NO XXX XXX 000100
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table className="v2" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Shipment customers</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Luggage type</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Total Weight (Kgs)</TableCell>
                  <TableCell>Offer</TableCell>
                  <TableCell>status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>
                    <div className="user_block">
                      <figure onClick={() => navigate("/profile-detail")}>
                        <img src="/static/images/user_attachment.jpg" alt="" />
                      </figure>
                      <p>
                        <b>Emma smith</b> samith@gmail.com
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>+963024587412</TableCell>
                  <TableCell className="double_value">
                    <span>Suite case</span>
                    <span>Box</span>
                  </TableCell>
                  <TableCell className="double_value">
                    <span>2</span>
                    <span>2</span>
                  </TableCell>
                  <TableCell className="double_value">
                    <span>10KG</span>
                    <span>10KG</span>
                  </TableCell>
                  <TableCell className="double_value">
                    <span>$90</span>
                    <span>$90</span>
                  </TableCell>
                  <TableCell>
                    <Button className="btn_text c_danger">
                      Retuned Request
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion> */}
    </>
  );

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          columns={columns}
          rows={rows}
          gridApi={gridApi}
          setSelectedRow={setSelectedRow}
          columnVisibilityModel={columnVisibilityModel}
        />
      </div>
      <AddComment
        open={openModal3}
        onClose={handleCloseModal3}
        setOpen={setOpenModal3}
        commentData={comment}
        setCommentData={setComment}
        editByIdData={() => editByIdData(selectedId, { comment: comment })}
      />

      <EditDepartureDate
        open={openModal}
        onClose={handleCloseModal}
        setOpen={setOpenModal}
        departureDate={departureDate}
        setDepartureDate={setDepartureDate}
        editByIdData={() =>
          editByIdData(selectedId, {
            travelDetail: comment?.map((data: any, index: number) =>
              index == 0
                ? {
                    ...data,
                    departureDate: dayjs(`${departureDate}`).add(1, "day"),
                  }
                : data
            ),
          })
        }
      />
      <ShipmentSummaryModal
        open={openModal2}
        onClose={handleCloseModal2}
        setOpen={setOpenModal2}
        accordionContent={accordionContent}
      />
    </>
  );
};

export default DelayedFlightsList;
