import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, Button, setRef, Typography } from "@mui/material";
import { showError, showToast } from "../constants/toasts";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { isArabicText } from "../utils/validations";

interface AddFaqModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  addFaq: any;
  getFaqList: any;
}

export default function AddFaq({
  open,
  onClose,
  setOpen,
  addFaq,
  getFaqList,
}: AddFaqModalProps) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(false);
  const [is_arabic, setis_arabic] = useState(false);
  const addFaqData = async () => {
    const body = {
      question: question,
      answer: answer,
      isArabic: is_arabic,
    };
    const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    try {
      const response = await addFaq(encryptedBody).unwrap();
      if (response?.statusCode == 200) {
        showToast("FAQ added successfully");
        getFaqList();
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError(true);
    if (question.trim() == "") {
      // showError("Question is required");
      return;
    }
    if (answer.trim() == "") {
      // showError("Answer is required")
      return;
    }
    await addFaqData();
    setError(false);
    setAnswer("");
    setQuestion("");
    setOpen(false);
  };
  useEffect(() => {
    setAnswer("");
    setQuestion("");
    setis_arabic(false);
  }, [open]);
  useEffect(() => {
    if (isArabicText(question) || isArabicText(answer)) {
      setis_arabic(true);
    } else {
      setis_arabic(false);
    }
  }, [question, answer]);
  return (
    <Modal
      className="modal addNotification_modal"
      id="addFaqModal"
      aria-labelledby="addFaq-modal-title"
      aria-describedby="addFaq-modal-description"
      open={open}
      onClose={() => {
        onClose();
        setError(false);
      }}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Add FAQ</h2>
          </div>
          <form className="form" onSubmit={handleSubmit}>
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Question"
                fullWidth
                dir="auto"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                hiddenLabel
              />
              {error && question?.trim() == "" ? (
                <h6 className="err_msg">Question is required</h6>
              ) : (
                ""
              )}
            </div>
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Answer"
                fullWidth
                hiddenLabel
                dir="auto"
                multiline
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                maxRows={5}
                minRows={5}
              />
              {error && answer?.trim() == "" ? (
                <h6 className="err_msg">Answer is required</h6>
              ) : (
                ""
              )}
            </div>
            <Typography
              className="custom_label"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>English</div>
              <div className="checkbox-wrapper-25">
                <input
                  type="checkbox"
                  checked={is_arabic}
                  onClick={() => setis_arabic(!is_arabic)}
                />
              </div>
              <div>Arabic</div>
            </Typography>

            <div className="footer_btn_flex">
              <Button
                className="btn btn_highlight"
                onClick={() => {
                  setError(false);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button className="btn btn_secondary" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
