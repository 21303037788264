import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
  redirect,
} from "react-router-dom";
import Pages from "./pages";
import MainContainer from "./layout/MainContainer";
import AuthLayout from "./layout/authLayout";
import Features from "./features";
import { ADMIN_ROLE_ID } from "./constants/role";
import secureLocalStorage from "react-secure-storage";

const Routing = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<Pages.Login />} />
          <Route path="forgot-password" element={<Pages.ForgotPassword />} />
          <Route path="reset-password" element={<Pages.ResetPassword />} />
          <Route path="review/:id" element={<Features.ReviewLink />} />
        </Route>

        <Route element={<MainContainer />}>
          <Route path="dashboard">
            <Route index element={<Pages.Dashboard />} />
            <Route
              path="downloaded-users"
              element={<Pages.DownloadedUsers />}
              loader={async () => {
                if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                  return null;
                if (
                  secureLocalStorage?.getItem("permissions") &&
                  secureLocalStorage?.getItem("permissions") !== "undefined" &&
                  !JSON.parse(
                    (secureLocalStorage?.getItem("permissions") as string) || ""
                  )?.[6]?.isView
                ) {
                  throw redirect("/dashboard");
                }
                return null;
              }}
              errorElement={<Pages.Dashboard />}
            />
            <Route
              path="uninstalled-users"
              element={<Pages.UninstalledUsers />}
              loader={async () => {
                if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                  return null;
                if (
                  secureLocalStorage?.getItem("permissions") &&
                  secureLocalStorage?.getItem("permissions") !== "undefined" &&
                  !JSON.parse(
                    (secureLocalStorage?.getItem("permissions") as string) || ""
                  )?.[6]?.isView
                ) {
                  throw redirect("/dashboard");
                }
                return null;
              }}
              errorElement={<Pages.Dashboard />}
            />
            <Route
              path="registered-users"
              element={<Pages.RegisteredUsers />}
              loader={async () => {
                if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                  return null;
                if (
                  secureLocalStorage?.getItem("permissions") &&
                  secureLocalStorage?.getItem("permissions") !== "undefined" &&
                  !JSON.parse(
                    (secureLocalStorage?.getItem("permissions") as string) || ""
                  )?.[6]?.isView
                ) {
                  throw redirect("/dashboard");
                }
                return null;
              }}
              errorElement={<Pages.Dashboard />}
            />
            <Route
              path="unregistered-users"
              element={<Pages.UnregisteredUsers />}
              loader={async () => {
                if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                  return null;
                if (
                  secureLocalStorage?.getItem("permissions") &&
                  secureLocalStorage?.getItem("permissions") !== "undefined" &&
                  !JSON.parse(
                    (secureLocalStorage?.getItem("permissions") as string) || ""
                  )?.[6]?.isView
                ) {
                  throw redirect("/dashboard");
                }
                return null;
              }}
              errorElement={<Pages.Dashboard />}
            />
            <Route
              path="invited-users"
              element={<Pages.InvitedUsers />}
              loader={async () => {
                if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                  return null;
                if (
                  secureLocalStorage?.getItem("permissions") &&
                  secureLocalStorage?.getItem("permissions") !== "undefined" &&
                  !JSON.parse(
                    (secureLocalStorage?.getItem("permissions") as string) || ""
                  )?.[6]?.isView
                ) {
                  throw redirect("/dashboard");
                }
                return null;
              }}
              errorElement={<Pages.Dashboard />}
            />
          </Route>
          <Route
            path="registrations"
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[0]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          >
            <Route index element={<Navigate replace to={"travelers"} />} />
            <Route path="travelers" element={<Pages.Travelers />} />
            <Route
              path="freight-customers"
              element={<Pages.FreightCustomers />}
            />
            <Route
              path="beneficiary-customers"
              element={<Pages.BeneficiaryCustomers />}
            />
          </Route>

          <Route
            path="shipment-track"
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[1]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          >
            <Route index element={<Navigate replace to={"new-orders"} />} />
            <Route path="new-orders" element={<Pages.NewOrders />} />
            <Route
              path="deliver-to-travelers"
              element={<Pages.DeliverToTravelers />}
            />
            <Route
              path="passenger-flight"
              element={<Pages.PassengerFlight />}
            />
            <Route path="feedback" element={<Pages.ShipmentFeedback />} />
          </Route>

          <Route
            path="/report-an-issue"
            element={<Pages.ReportAnIssue />}
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[2]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          />

          <Route
            path="/looking-for-travellers"
            element={<Pages.LookingForTravellers />}
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[3]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          />

          <Route
            path="membership-level"
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[5]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          >
            <Route index element={<Navigate replace to={"bronze"} />} />
            <Route path="bronze" element={<Pages.MembershipLevelBronze />} />
            <Route path="silver" element={<Pages.MembershipLevelBronze />} />
            <Route path="gold" element={<Pages.MembershipLevelBronze />} />
            {/* <Route path="silver" element={<Pages.MembershipLevelSilver />} />
            <Route path="gold" element={<Pages.MembershipLevelGold />} /> */}
          </Route>

          <Route
            path="/reports"
            element={<Pages.Reports />}
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[4]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          />

          <Route
            path="user-management"
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[6]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          >
            <Route index element={<Navigate replace to={"roles"} />} />

            <Route path="roles">
              <Route index element={<Pages.Roles />} />
              <Route
                path="details"
                element={
                  <Navigate to={"/user-management/roles"} replace={true} />
                }
              />
              <Route path="details/:id" element={<Pages.UserList />} />
            </Route>

            <Route path="user-list" element={<Pages.UserList />} />
          </Route>

          <Route
            path="/manage-notifications"
            element={<Pages.ManageNotifications />}
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[7]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          />
          <Route
            path="/recieved-notifications"
            element={<Pages.RecievedNotifications />}
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[7]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          />

          <Route
            path="/manage-cms"
            element={<Pages.ManageCms />}
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[8]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          />

          <Route
            path="/broadcast-message"
            element={<Pages.BroadcastMessage />}
          />
          <Route path="/chat" element={<Pages.Chat />} />
          <Route
            path="/profile-detail/:id"
            element={<Pages.ProfileDetail />}
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[6]?.isView
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          />
          {/* <Route path="/profile-detail" element={<Pages.ProfileDetail />} /> */}
          <Route
            path="/edit-profile-detail/:id"
            element={<Pages.EditProfile />}
            loader={async () => {
              if (secureLocalStorage.getItem("role") == ADMIN_ROLE_ID)
                return null;
              if (
                secureLocalStorage?.getItem("permissions") &&
                secureLocalStorage?.getItem("permissions") !== "undefined" &&
                !JSON.parse(
                  (secureLocalStorage?.getItem("permissions") as string) || ""
                )?.[6]?.isEdit
              ) {
                throw redirect("/dashboard");
              }
              return null;
            }}
            errorElement={<Pages.Dashboard />}
          />
        </Route>
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

export default Routing;
