/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Dispatch, SetStateAction, useState } from "react";
import TableComponent from "../../components/TableComponent";
import PassportModal from "../../Modals/passportModal";
import { IconButton, Box, Menu, MenuItem, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { InfoTwoTone } from "@mui/icons-material";
import { showToast, showError } from "../../constants/toasts";
import { useEditFreightCustomerByIdMutation } from "../../services/freightCustomer";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import ShippingGallery from "../../Modals/shippingGallery";
import RejectRevert from "../../Modals/rejectRevert";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";
import { CommonBody } from "../../types/General";
import ViewComment from "../../Modals/viewComment";

type props = {
  hidden: string[];
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  columnVisibilityModel: any;
  freightCustomer: any;
  setSelectedRow: any;
  gridApi: any;
  getDataList: any;
  setTotalDataCount: any;
};

const NewFreightList = ({
  hidden,
  openModal,
  setOpenModal,
  columnVisibilityModel,
  freightCustomer,
  setSelectedRow,
  gridApi,
  getDataList,
  setTotalDataCount,
}: props) => {
  const navigate = useNavigate();
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [selectedId, setSelectedId] = useState("");
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const handleRejectModalClose = () => {
    setOpenRejectModal(false);
  };
  // console.log('selectedRow :', selectedRow);
  const [openModal4, setOpenModal4] = useState(false);
  function handleCloseModal4() {
    setOpenModal4(!openModal4);
  }
  const [imageArray, setImageArray] = useState<any>([]);
  const [valueTabs, setValueTabs] = React.useState(0);
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };
  const [idData, setIdData] = useState({
    idCard: "",
    residenceCard: "",
  });
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const getResidentTypeName = (residenceType: number) => {
    if (residenceType == 1) {
      return "CITIZEN";
    } else if (residenceType == 2) {
      return "RESIDENT";
    } else if (residenceType == 3) {
      return "VISITOR";
    } else {
      return "";
    }
  };
  const getTravelTypeName = (travelType: number) => {
    if (travelType == 1) {
      return "Private Vehicle";
    } else if (travelType == 2) {
      return "Airlines";
    } else if (travelType == 3) {
      return "Voyage";
    } else if (travelType == 4) {
      return "Railway";
    } else if (travelType == 5) {
      return "Public Road";
    } else {
      return "";
    }
  };
  const getParcelCategoryName = (categoryType: number) => {
    const CATEGORIES: any = {
      1: "Pharmaceutical & Health care products and Books",
      2: "Beauty & Perfumes",
      3: "Clothes",
      4: "Foodstuff",
      5: "Mobile, Tablet, Laptop, and accessories",
      6: "Home tools, Furniture and Toys.",
      7: "Documents and printed matter",
      8: "Other",
    };
    return CATEGORIES[categoryType];
  };
  const getLuggageName = (luggageType: number) => {
    const CARGO_TYPE: any = {
      1: "ENVELOPE",
      2: "CARTON",
      3: "SUITCASE",
      4: "BOX",
      5: "BARREL",
      6: "OTHER",
    };

    return CARGO_TYPE[luggageType];
  };
  const [updateData] = useEditFreightCustomerByIdMutation();
  const [reason, setReason] = useState("");
  const handleStatusChange = async (id: any, adminStatus: any) => {
    try {
      const body =
        adminStatus == 3
          ? {
              cancelReason: reason,
              adminStatus: adminStatus,
            }
          : {
              adminStatus: adminStatus,
            };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body) as CommonBody;
      const response = await updateData({
        id: `${id}`,
        body: encryptedData,
      }).unwrap();
      showToast("Freight customer status updated successfully");
      getDataList();
      setAnchorEl1(null);
      setTotalDataCount((prevData: any) => {
        if (adminStatus == 2)
          return {
            ...prevData,
            type1: prevData.type1 - 1,
            type2: prevData.type2 + 1,
          };
        if (adminStatus == 3)
          return {
            ...prevData,
            type1: prevData.type1 - 1,
            type3: prevData.type3 + 1,
          };
        return prevData;
      });
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  const [comment, setComment] = useState<string[]>([]);
  const [openModal3, setOpenModal3] = useState(false);
  const handleCloseModal3 = () => {
    setOpenModal3(false);
  };
  const ActionCell = ({ data }: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const travelerId = data?.row?.id;

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false);
    };

    return (
      <div className="table_actions">
        <Box>
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img src="/static/images/more_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => handleStatusChange(data?.row?.id, 2)}>
              Approve
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedId(data?.row?.id);
                setOpenRejectModal(true);
              }}
            >
              Reject
            </MenuItem>
            <MenuItem
              onClick={() =>
                navigate("/edit-profile-detail/" + data?.row?.userId, {
                  state: "/registrations/freight-customers",
                })
              }
            >
              Edit
            </MenuItem>
          </Menu>
        </Box>
      </div>
    );
  };

  // let hidden = ["fullName"];
  const rows: any = [];
  freightCustomer?.map((data: any) => {
    rows?.push({
      id: data?._id,
      TR: "TR" + data?.shipmentId,
      name: data?.userDetails?.[0]?.fullName,
      phone: data?.userDetails?.[0]?.phone,
      countryCode: data?.userDetails?.[0]?.countryCode,
      mobile:
        data?.userDetails?.[0]?.countryCode + data?.userDetails?.[0]?.phone,
      email: data?.userDetails?.[0]?.email,
      image: data?.userDetails?.[0]?.personVerification?.profileImage,
      residencyType: getResidentTypeName(
        data?.personVerification?.residenceType
      ),
      categoryData: data?.packageDetail,
      currentCity: data?.beneficiaryDetail?.customerCurrentCity,
      destinationCity: data?.beneficiaryDetail?.city,
      customerComment: "-",
      idCard: data?.personVerification?.idCard,
      residenceCard: data?.personVerification?.residenceCard,
      userId: data?.userDetails?.[0]?._id,
      packageDetail: data?.packageDetail,
      shipmentId: "SH" + data?.shipmentId,
    });
  });
  const user = useAuth();
  const columns: GridColDef<(typeof rows)[number]>[] = [
    // {
    //   flex: 1,
    //   minWidth: 160,
    //   field: "TR",
    //   headerName: "TR",
    //   editable: false,
    // },
    {
      flex: 1,
      minWidth: 210,
      field: "name",
      headerName: "Name",
      editable: false,
      renderCell: ({ row }) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + row?.userId)
                  : null
              }
            >
              <img
                src={
                  row?.image
                    ? row?.image
                    : "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <Tooltip
              title={
                <p style={{ color: "white" }}>
                  {row?.name}
                  <br />
                  {row?.mobile}
                  <br />
                  {row?.email}
                </p>
              }
            >
              <p className="cursor_p">
                <b>{row?.name}</b>
                {row?.mobile}
                <br />
                {row?.email}
              </p>
            </Tooltip>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 110,
      field: "passportId",
      headerName: "Passport ID",
      editable: false,
      sortable: false,
      disableExport: true,
      align: "center",
      headerAlign: "center",
      renderCell: (data: any) => {
        return (
          <IconButton
            className="text_icon"
            onClick={() => {
              setIdData({
                idCard: data?.row?.idCard,
                residenceCard: data?.row?.residenceCard,
              });
              if (data?.row?.residenceCard || data?.row?.idCard)
                setOpenModal(true);
            }}
          >
            <img src="/static/images/external_icon.svg" alt="" />
          </IconButton>
        );
      },
    },
    {
      flex: 1,
      minWidth: 130,
      field: "shipmentId",
      headerName: "Shipment Id",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 130,
      field: "residencyType",
      headerName: "Residency Type",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 140,
      field: "currentCity",
      headerName: "Current City",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 160,
      field: "destinationCity",
      headerName: "Destination City",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "category",
      headerName: "Category",
      editable: false,
      sortable: false,
      renderCell: (data) => {
        const categoryArray: number[] = [];
        const luggageArray = [];
        data?.row?.categoryData?.map((data2: any) => {
          luggageArray?.push(data2?.cargoType);
          Array.isArray(data2?.parcelCategory) &&
            data2?.parcelCategory?.map((data3: any) => {
              categoryArray.push(data3);
            });
        });
        const displayData = data?.row?.categoryData?.map(
          (data2: any, index: number) => {
            return (
              <ul>
                <h3>{index + 1 + ". " + getLuggageName(data2?.cargoType)}</h3>

                {Array.isArray(data2?.parcelCategory) &&
                  data2?.parcelCategory?.map((data3: any) => {
                    return <li>{getParcelCategoryName(data3)}</li>;
                  })}
              </ul>
            );
          }
        );
        return (
          <>
            {categoryArray?.length == 1 ? (
              <p>{getParcelCategoryName(categoryArray[0])}</p>
            ) : (
              <p style={{ display: "flex", alignItems: "center" }}>
                {categoryArray?.length + " Categories  "}
                <Tooltip title={displayData} arrow>
                  <IconButton>
                    <InfoTwoTone />
                  </IconButton>
                </Tooltip>
              </p>
            )}
          </>
        );
      },
    },
    {
      flex: 1,
      minWidth: 170,
      field: "shipmentPictures",
      headerName: "Shipment Pictures",
      editable: false,
      sortable: false,
      align: "center",
      renderCell: (data) => {
        return (
          <IconButton
            className="text_icon"
            onClick={() => {
              const temp: any = [];
              data?.row?.packageDetail.map((row: any) => {
                temp.push(row?.side);
                temp.push(row?.photo);
                temp.push(row?.front);
                temp.push(row?.back);
              });
              setImageArray(temp);
              setOpenModal4(true);
            }}
          >
            <img src="/static/images/external_icon.svg" alt="" />
          </IconButton>
        );
      },
    },
    {
      flex: 1,
      minWidth: 190,
      field: "customerComment",
      headerName: "Customer Comment",
      sortable: false,
      editable: false,
      align: "center",
      renderCell: (data: any) => {
        return (
          <p
            className="view_link"
            onClick={() => {
              setComment(
                data?.row?.packageDetail?.map(
                  (data: any) => data.parcelDescription
                )
              );
              setOpenModal3(true);
            }}
          >
            Comment <img src="/static/images/external_icon.svg" alt="Icon" />
          </p>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "mobile",
      headerName: "Mobile",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "email",
      headerName: "Email Address",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 160,
      field: "luggageType",
      sortable: false,
      headerName: "Luggage Type",
      editable: false,
      renderCell: (data) => {
        const displayData = data?.row?.categoryData?.map(
          (data2: any, index: number) => {
            return (
              <ul>
                <h3>
                  {index + 1 + ". "}
                  {getLuggageName(data2?.cargoType)}
                </h3>
              </ul>
            );
          }
        );
        return (
          <>
            {data?.row?.categoryData?.length == 1 ? (
              <p>{getLuggageName(data?.row?.categoryData?.[0]?.cargoType)}</p>
            ) : (
              <p style={{ display: "flex", alignItems: "center" }}>
                {data?.row?.categoryData?.length + " Luggages  "}
                <Tooltip title={displayData} arrow>
                  <IconButton>
                    <InfoTwoTone />
                  </IconButton>
                </Tooltip>
              </p>
            )}
          </>
        );
      },
    },
    {
      flex: 1,
      minWidth: 80,
      disableExport: true,
      field: "action",
      headerName: "Action",
      editable: false,
      sortable: false,
      renderCell: (data) => <ActionCell data={data} />,
    },
  ];

  //   const lastColumn = columns.pop();
  //   console.log(lastColumn, "lastColumn");
  let nonAction = columns;
  return (
    <>
      <div className="sorting_table">
        <TableComponent
          gridApi={gridApi}
          setSelectedRow={setSelectedRow}
          columns={columns?.filter((item: any) => !hidden.includes(item.field))}
          rows={rows}
          columnVisibilityModel={columnVisibilityModel}
        />
      </div>

      <PassportModal
        open={openModal}
        onClose={handleCloseModal}
        setOpen={setOpenModal}
        idData={idData}
      />
      <ShippingGallery
        open={openModal4}
        onClose={handleCloseModal4}
        setOpen={setOpenModal4}
        imageArray={imageArray}
        selectedImageIndex={0}
      />
      <RejectRevert
        open={openRejectModal}
        onClose={handleRejectModalClose}
        setOpen={setOpenRejectModal}
        handleStatusChange={() => handleStatusChange(selectedId, 3)}
        reason={reason}
        setReason={setReason}
      />
      <ViewComment
        open={openModal3}
        onClose={handleCloseModal3}
        setOpen={setOpenModal3}
        comment={comment}
      />
    </>
  );
};

export default NewFreightList;
