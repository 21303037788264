import { useMemo } from "react";
import { useAppSelector } from "./store";
import { getCurrentUser } from "../reducers/authSlice";

function useAuth() {
  let user = useAppSelector(getCurrentUser);
  // const permissions=[
  //   {
  //     label: 'Manage Users',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   },
  //   {
  //     label: 'Manage Recipe',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   },
  //   {
  //     label: 'Manage Workout Video',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   },
  //   {
  //     label: 'Manage Bio-Hacking',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   },
  //   {
  //     label: 'Workout Fasting',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   },
  //   {
  //     label: 'Manage Subscription',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   },
  //   {
  //     label: 'Manage Survey Questionaires',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   },
  //   {
  //     label: 'Notification Management',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   },
  //   {
  //     label: 'Analytics',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   },
  //   {
  //     label: 'CMS',
  //     isEdit: true,
  //     isView: true,
  //     isDelete: true,
  //     disabled: true
  //   }
  // ]
  // if(user?.role==1)
  // {
  //   user={...user,permissions:permissions}
  // }

  return useMemo(() => user, [user]);
}

export default useAuth;
